import { FC } from 'react'
import * as styles from './branch-name.css'
import { TooltipWithHover } from './tooltip'

interface BranchNameProps {
  branch: string
}

export const BranchName: FC<BranchNameProps> = ({ branch }) => {
  const shouldTruncate = branch.length > 25

  return (
    <div className={styles.branchContainer}>
      {shouldTruncate ? (
        <TooltipWithHover trigger={<span className={styles.branchText}>{`${branch.slice(0, 22)}...`}</span>}>
          {branch}
        </TooltipWithHover>
      ) : (
        <span className={styles.branchText}>{branch}</span>
      )}
    </div>
  )
}
