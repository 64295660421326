import { TrashIcon } from '@radix-ui/react-icons'
import classNames from 'classnames'
import React, { forwardRef } from 'react'
import * as styles from './default-button.css.ts'
import { Spinner } from './spinner'

type DefaultButtonProps = {
  children: React.ReactNode
  buttonType?: 'primary' | 'secondary' | 'destructive'
  buttonRole?: 'button' | 'submit' | 'reset'
  state?: 'default' | 'disabled' | 'loading'
  size?: 'default' | 'small'
  href?: string
  openInNewTab?: boolean
  className?: string
  ariaLabel?: string
  onClick?: (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void
  onKeyDown?: (event: React.KeyboardEvent<HTMLButtonElement | HTMLAnchorElement>) => void
  loadingLabel?: string
}

export const DefaultButton = forwardRef<HTMLButtonElement | HTMLAnchorElement, DefaultButtonProps>(
  (
    {
      children,
      buttonType = 'primary',
      state = 'default',
      size = 'default',
      href,
      openInNewTab = true,
      className,
      onClick,
      onKeyDown,
      buttonRole = 'button',
      ariaLabel,
      loadingLabel = 'Loading',
    },
    ref
  ) => {
    if (href)
      return (
        <a
          ref={ref as React.Ref<HTMLAnchorElement>}
          className={classNames(styles.button({ buttonType, state, size }), className)}
          onMouseDown={e => {
            e.preventDefault()
          }}
          href={state === 'disabled' ? '#' : href}
          target={openInNewTab ? '_blank' : undefined}
          rel={openInNewTab ? 'noopener noreferrer' : undefined}
          onClick={onClick}
          onKeyDown={onKeyDown}
        >
          {children}
        </a>
      )

    const child =
      buttonType === 'destructive' ? (
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <TrashIcon className={styles.trashIcon} />
          {children}
        </div>
      ) : (
        children
      )

    return (
      <button
        ref={ref as React.Ref<HTMLButtonElement>}
        className={classNames(styles.button({ buttonType, state, size }), className)}
        disabled={state === 'disabled' || state === 'loading'}
        onMouseDown={e => {
          e.preventDefault()
        }}
        onClick={onClick}
        onKeyDown={onKeyDown}
        aria-busy={state === 'loading'}
        type={buttonRole}
        aria-label={ariaLabel}
      >
        {state === 'loading' ? <Spinner label={loadingLabel} /> : child}
      </button>
    )
  }
)

DefaultButton.displayName = 'DefaultButton'
