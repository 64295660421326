import * as Dialog from '@radix-ui/react-dialog'
import { ExclamationTriangleIcon } from '@radix-ui/react-icons'
import { repositoryTypeToSCMIcon } from '../routes/repositories-page.tsx'
import { AnyRepositoryResponse } from '../utils/api-client/user-platform-api-schemas'
import { DefaultButton } from './default-button'
import * as styles from './delete-repository-dialog.css.ts'
import { GhostButton } from './ghost-button.tsx'

export const DeleteRepositoryDialog: React.FC<{
  repository: {
    id: AnyRepositoryResponse['id']
    name: AnyRepositoryResponse['name']
    type: AnyRepositoryResponse['type']
  }
  handleDeleteRepository?: (repositoryId: string) => void
  handleCloseDeleteRepositoryDialog?: () => void
}> = ({ repository, handleDeleteRepository = () => {}, handleCloseDeleteRepositoryDialog = () => {} }) => {
  return (
    <Dialog.Root open={true}>
      <Dialog.Overlay className={styles.alertDialogOverlay} />
      <Dialog.Content className={styles.alertDialogContent}>
        <Dialog.Title className={styles.alertDialogTitle}>
          <ExclamationTriangleIcon className={styles.alertDialogIcon} />
          Delete repository?
        </Dialog.Title>
        <Dialog.Description className={styles.alertDialogDescription}>
          <p className={styles.alertDialogDescriptionParagraph}>
            You are about to delete the following repository, including its associated analysis data:
          </p>
          <p className={styles.alertDialogRepository}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
              {repositoryTypeToSCMIcon({ type: repository.type })}
              {repository.name}
            </div>
          </p>
          <p className={styles.alertDialogDescriptionParagraph}>
            This action cannot be undone, and the data cannot be retrieved. Do you wish to proceed?
          </p>
        </Dialog.Description>
        <div className={styles.alertDialogButtons}>
          <GhostButton type="secondary" onClick={handleCloseDeleteRepositoryDialog}>
            Cancel
          </GhostButton>
          <DefaultButton buttonType="destructive" onClick={() => handleDeleteRepository(repository.id)}>
            Delete repository
          </DefaultButton>
        </div>
      </Dialog.Content>
    </Dialog.Root>
  )
}
