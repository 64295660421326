import * as Dialog from '@radix-ui/react-dialog'
import * as commonStyles from '../styles/common.css.ts'
import { DefaultButton } from './default-button'
import * as styles from './onboarding-dialog.css.ts'
import OnboardingLogos from './svg/onboarding-logos.svg?react'

export function OnboardingDialog({ handleCompleteOnboarding }: { handleCompleteOnboarding: () => void }) {
  return (
    <Dialog.Root open={true}>
      <Dialog.Portal>
        <Dialog.Overlay className={styles.alertDialogOverlay} />
        <Dialog.Content className={styles.alertDialogContent}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '32px' }}>
            <Dialog.Title className={styles.welcome}>Welcome! 👋</Dialog.Title>
            <Dialog.Description asChild>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                <h1 className={styles.mainHeading}>Connect your repository and upload a scan</h1>
                <p className={styles.paragraph}>
                  Once a repository is connected, upload a SARIF from your supported SAST tool to get in-depth triage
                  analysis and fixes for findings.{' '}
                  <a
                    href="https://docs.pixee.ai/code-scanning-tools/overview"
                    target="_blank"
                    className={commonStyles.link}
                  >
                    Learn more
                  </a>{' '}
                  about integrating with supported tools.
                </p>
              </div>
            </Dialog.Description>
          </div>
          <OnboardingLogos />
          <div className={styles.onboardingDialogFooter}>
            <p className={styles.additionalToolIntegrations}>
              Additional tool integrations are in the works; stay tuned!
            </p>
            <DefaultButton onClick={() => handleCompleteOnboarding()} ariaLabel="Close onboarding dialog">
              Let's go
            </DefaultButton>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
