import { createId } from '@paralleldrive/cuid2'
import * as Portal from '@radix-ui/react-portal'
import { createContext, PropsWithChildren, useCallback, useContext, useMemo, useState } from 'react'
import { Toast } from '../../components/toast'
import { ToastVariants } from '../../components/toast.css'
import { ToastsContainer } from '../../components/toasts-container'

type ToastsContextType = {
  handleAddToastWithTimeout: HandleAddToastWithTimeout
}

const ToastsContext = createContext<ToastsContextType | undefined>(undefined)

export const useAddToast = () => {
  const context = useContext(ToastsContext)
  if (!context) throw new Error('useAddToast must be used within a WithToasts component')
  return context
}

type Toast = { id: string; message: JSX.Element; variant: ToastVariants }

export const WithToasts = ({ children }: PropsWithChildren) => {
  const [toasts, setToasts] = useState<Toast[]>([])

  const handleAddToastWithTimeout: HandleAddToastWithTimeout = useCallback(toastProps => {
    const id = createId()
    setToasts(toasts => [...toasts, { ...toastProps, id }])
    setTimeout(() => {
      setToasts(currentToasts => currentToasts.filter(toast => toast.id !== id))
    }, 3000)
  }, [])

  const contextValue = useMemo(() => ({ handleAddToastWithTimeout }), [handleAddToastWithTimeout])

  return (
    <ToastsContext.Provider value={contextValue}>
      <Portal.Root asChild>
        <ToastsContainer>
          {toasts.map(toast => (
            <Toast key={toast.id} variant={toast.variant}>
              {toast.message}
            </Toast>
          ))}
        </ToastsContainer>
      </Portal.Root>
      {children}
    </ToastsContext.Provider>
  )
}

type HandleAddToastWithTimeout = (toastProps: { message: JSX.Element; variant: ToastVariants }) => void
