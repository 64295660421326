import { ArrowDownIcon, ArrowUpIcon } from '@radix-ui/react-icons'
import React from 'react'
import { Severity } from '../utils/api-client/user-platform-api-schemas'
import * as styles from './suggested-severity.css.ts'

interface SuggestedSeverityProps {
  severity: Severity | null
  suggestedSeverity: Severity | null
}

export const SuggestedSeverity: React.FC<SuggestedSeverityProps> = ({ severity, suggestedSeverity }) => {
  if (
    severity === null ||
    suggestedSeverity === null ||
    severity.label === suggestedSeverity.label ||
    severity.label === null ||
    suggestedSeverity.label === null ||
    severity.label.toLowerCase() === 'unknown' ||
    suggestedSeverity.label.toLowerCase() === 'unknown'
  )
    return <p className={styles.placeholder}>-</p>

  const severityIncreased = severity.rank < suggestedSeverity.rank

  return (
    <div className={styles.container}>
      {severityIncreased ? (
        <ArrowUpIcon className={styles.arrowUp} aria-label="Severity increased." role="img" />
      ) : (
        <ArrowDownIcon className={styles.arrowDown} aria-label="Severity decreased." role="img" />
      )}
      <p className={styles.text}>{`${capitalize(severity.label)} to ${capitalize(suggestedSeverity.label)}`}</p>
    </div>
  )
}

const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1)
