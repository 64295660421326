import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { Routes } from '../routes'
import { ScanAnalysis } from '../utils/api-client/user-platform-api-schemas'
import * as styles from './latest-analysis-link.css.ts'
import CloseIcon from './svg/close-icon.svg?react'
import DotIcon from './svg/dot-icon.svg?react'

function formatTimeAgo(timestamp: string): string {
  const diff = Date.now() - new Date(timestamp).getTime()
  const minutes = Math.floor(diff / 60000)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)

  if (days > 0) {
    return `${days} day${days === 1 ? '' : 's'} ago`
  }
  if (hours > 0) {
    return `${hours} hr${hours === 1 ? '' : 's'} ago`
  }
  return `${minutes} min ago`
}

export function LatestAnalysisLink({ latestAnalysis }: { latestAnalysis: ScanAnalysis }) {
  const toolName = (latestAnalysis._embedded.scan.detector || 'Analysis')
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
  const isInProgress = ['queued', 'in_progress'].includes(latestAnalysis.current_state.state)
  const isFailed = latestAnalysis.current_state.state === 'failed'
  const isCompleted = ['completed_results', 'completed_no_results'].includes(latestAnalysis.current_state.state)
  const showNeutralStyle = isInProgress || isFailed

  return (
    <Link
      to={Routes.AnalysisDetailsPage.createPath({ analysisId: latestAnalysis.id })}
      className={classNames(styles.analysisLink, {
        [styles.inProgress]: showNeutralStyle,
      })}
    >
      {toolName}
      {isCompleted && (
        <span className={styles.completedLabel}>{formatTimeAgo(latestAnalysis.current_state.timestamp)}</span>
      )}
      {isInProgress && <DotIcon className={styles.progressIcon} />}
      {isFailed && <CloseIcon className={styles.closeIcon} />}
    </Link>
  )
}
