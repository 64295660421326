import { GitMergeIcon, MarkGithubIcon } from '@primer/octicons-react'
import { ArrowRightIcon } from '@radix-ui/react-icons'
import * as Tabs from '@radix-ui/react-tabs'
import { FC, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { AnalysisStatusBadge } from '../components/analysis-status-badge'
import { BranchName } from '../components/branch-name'
import { PaginationControls } from '../components/pagination-controls'
import { Pagination } from '../components/table.tsx'
import { typography } from '../main.css.ts'
import { Routes } from '../routes.ts'
import { useGetAllAnalyses } from '../utils/api-client/user-platform-api-hooks.ts'
import { ScanAnalysis } from '../utils/api-client/user-platform-api-schemas.ts'
import { useTheme } from '../utils/higher-order-components/with-theme'
import { mapToolToLogoAndName } from './analysis-details-page'
import * as styles from './scans-overview-page.css.ts'

export function ScansOverviewPage() {
  const [searchParams, setSearchParams] = useSearchParams()
  const activeTab = searchParams.get('tab') === 'in-progress' ? 'in-progress' : 'latest'

  const [pagination, setPagination] = useState<Pagination>({
    pageIndex: 0,
    pageSize: 10,
  })

  const { data: paginatedInProgressAnalyses, isLoading: inProgressLoading } = useGetAllAnalyses({
    states: ['queued', 'in_progress'],
    pageSize: pagination.pageSize,
    pageNumber: activeTab === 'in-progress' ? pagination.pageIndex : 0,
  })

  const { data: paginatedCompletedAnalyses, isLoading: completedLoading } = useGetAllAnalyses({
    states: ['completed_results', 'completed_no_results'],
    pageSize: pagination.pageSize,
    pageNumber: activeTab === 'latest' ? pagination.pageIndex : 0,
  })

  const isLoading = (activeTab === 'in-progress' && inProgressLoading) || (activeTab === 'latest' && completedLoading)

  const handlePaginationChange = (newPagination: Pagination) => {
    setPagination(newPagination)
  }

  return (
    <ScansOverviewPagePure
      isLoading={isLoading}
      completedAnalyses={paginatedCompletedAnalyses?._embedded.items ?? []}
      inProgressAnalyses={paginatedInProgressAnalyses?._embedded.items ?? []}
      activeTab={activeTab}
      onTabChange={tab => {
        setSearchParams({ tab })
        setPagination({ pageIndex: 0, pageSize: pagination.pageSize }) // Reset page when changing tabs
      }}
      pagination={pagination}
      onPaginationChange={handlePaginationChange}
      completedTotal={paginatedCompletedAnalyses?.total ?? 0}
      inProgressTotal={paginatedInProgressAnalyses?.total ?? 0}
    />
  )
}

export function ScansOverviewPagePure({
  isLoading,
  completedAnalyses,
  inProgressAnalyses,
  activeTab,
  onTabChange,
  pagination,
  onPaginationChange,
  completedTotal,
  inProgressTotal,
}: {
  isLoading: boolean
  completedAnalyses: ScanAnalysis[]
  inProgressAnalyses: ScanAnalysis[]
  activeTab: 'latest' | 'in-progress'
  onTabChange: (tab: 'latest' | 'in-progress') => void
  pagination: Pagination
  onPaginationChange: (pagination: Pagination) => void
  completedTotal: number
  inProgressTotal: number
}) {
  return (
    <div>
      <div className={styles.scansOverviewHeader} role="banner">
        <h4 className={`${typography.h4} ${styles.headerTitle}`}>Scans</h4>
        <p className={`${typography.paragraph} ${styles.headerParagraph}`}>
          Upload, triage and fix your SAST scan findings
        </p>
      </div>

      {isLoading ? (
        <div className={styles.loadingContainer}>Loading scans...</div>
      ) : (
        <Tabs.Root
          value={activeTab}
          onValueChange={value => onTabChange(value as 'latest' | 'in-progress')}
          className={styles.tabContainer}
        >
          <Tabs.List className={styles.tabList}>
            <Tabs.Trigger className={styles.tab} value="latest">
              LATEST &nbsp;<span className={styles.tabItemCount}>{completedTotal}</span>
            </Tabs.Trigger>
            <Tabs.Trigger className={styles.tab} value="in-progress">
              IN PROGRESS &nbsp;<span className={styles.tabItemCount}>{inProgressTotal}</span>
            </Tabs.Trigger>
          </Tabs.List>

          <Tabs.Content className={styles.tabContent} value="latest">
            {!isLoading && completedAnalyses.length === 0 ? (
              <p className={styles.noScansMessage}>
                No scans found. Please upload tool results to a connected repository.
              </p>
            ) : (
              <>
                {completedAnalyses.map(analysis => (
                  <ScanPure key={analysis.id} analysis={analysis} />
                ))}
                <div className={styles.paginationContainer}>
                  <PaginationControls
                    tableLabel="Completed Scans"
                    pageIndex={pagination.pageIndex}
                    pageSize={pagination.pageSize}
                    rowCount={completedTotal}
                    onNextPage={() => onPaginationChange({ ...pagination, pageIndex: pagination.pageIndex + 1 })}
                    onPreviousPage={() => onPaginationChange({ ...pagination, pageIndex: pagination.pageIndex - 1 })}
                    onChangePageSize={pageSize => onPaginationChange({ pageIndex: 0, pageSize })}
                  />
                </div>
              </>
            )}
          </Tabs.Content>

          <Tabs.Content className={styles.tabContent} value="in-progress">
            {!isLoading && inProgressAnalyses.length === 0 ? (
              <p className={styles.noScansMessage}>No scans in progress.</p>
            ) : (
              <>
                {inProgressAnalyses.map(analysis => (
                  <ScanPure key={analysis.id} analysis={analysis} />
                ))}
                <div className={styles.paginationContainer}>
                  <PaginationControls
                    tableLabel="In Progress Scans"
                    pageIndex={pagination.pageIndex}
                    pageSize={pagination.pageSize}
                    rowCount={inProgressTotal}
                    onNextPage={() => onPaginationChange({ ...pagination, pageIndex: pagination.pageIndex + 1 })}
                    onPreviousPage={() => onPaginationChange({ ...pagination, pageIndex: pagination.pageIndex - 1 })}
                    onChangePageSize={pageSize => onPaginationChange({ pageIndex: 0, pageSize })}
                  />
                </div>
              </>
            )}
          </Tabs.Content>
        </Tabs.Root>
      )}
    </div>
  )
}

const ScanPure: FC<{ analysis: ScanAnalysis }> = ({ analysis }) => {
  const { theme } = useTheme()
  const date = new Date(analysis.current_state.timestamp)
  const formattedDate = date.toLocaleDateString('en-US', {
    month: 'numeric',
    day: 'numeric',
    year: '2-digit',
  })
  const formattedTime = date.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true,
  })

  const RepositoryIcon =
    // @ts-expect-error TODO: need to embedded repository type in analysis https://app.devrev.ai/pixee/works/ISS-2888
    'git' === 'github'
      ? () => <MarkGithubIcon aria-label="GitHub icon" className={styles.scmIcon} />
      : () => <GitMergeIcon aria-label="Git icon" className={styles.scmIcon} />

  const { logoHref, name } = mapToolToLogoAndName(analysis._embedded.scan.detector, theme)

  return (
    <div className={styles.scansOuterContainer}>
      <div className={styles.scanContainer}>
        <div className={styles.firstCell}>
          <div className={styles.toolLogoContainer}>
            <img src={logoHref} alt={`${name} logo`} className={styles.toolLogo} />
          </div>
          <p className={styles.date}>{formattedDate}</p>
          <p className={styles.time}>{formattedTime}</p>
          {analysis._embedded.scan.branch && <BranchName branch={analysis._embedded.scan.branch} />}
        </div>
        <div className={styles.secondCell}>
          <RepositoryIcon />
          <p className={styles.repositoryName}>{analysis._embedded.scan._links.repository.title}</p>
        </div>
        <div className={styles.lastCell}>
          {analysis.current_state.state &&
            analysis.current_state.state !== 'completed_results' &&
            analysis.current_state.state !== 'completed_no_results' && (
              <AnalysisStatusBadge variant={analysis.current_state.state} />
            )}
          <Link to={Routes.AnalysisDetailsPage.createPath({ analysisId: analysis.id })} className={styles.analysisLink}>
            View analysis
            <ArrowRightIcon />
          </Link>
        </div>
      </div>
    </div>
  )
}
