import { useEffect, useState } from 'react'

type LocalDevData = {
  name: 'local-dev'
  logoIconHref: '/0-Icon-Trimmed.png'
  logoIconAlt: 'Pixee Logo'
  githubAppLogin: 'pixee-c5c93607aa1802b2'
  githubAppName: 'Pixeebot'
}

const localDevData: LocalDevData = {
  name: 'local-dev',
  logoIconHref: '/0-Icon-Trimmed.png',
  logoIconAlt: 'Pixee Logo',
  githubAppLogin: 'pixee-c5c93607aa1802b2',
  githubAppName: 'Pixeebot',
}

type PixeeSaaSData = {
  name: 'pixee-saas'
  logoIconHref: '/0-Icon-Trimmed.png'
  logoIconAlt: 'Pixee Logo'
  githubAppLogin: 'pixeebot'
  githubAppName: 'Pixeebot'
}

const pixeeSaaSData: PixeeSaaSData = {
  name: 'pixee-saas',
  logoIconHref: '/0-Icon-Trimmed.png',
  logoIconAlt: 'Pixee Logo',
  githubAppLogin: 'pixeebot',
  githubAppName: 'Pixeebot',
}

type NttData = {
  name: 'ntt'
  logoIconHref: '/ntt-logo.png'
  logoIconAlt: 'NTT Logo'
  githubAppLogin: 'ntt'
  githubAppName: 'Code Guardian™'
}

const nttData: NttData = {
  name: 'ntt',
  logoIconHref: '/ntt-logo.png',
  logoIconAlt: 'NTT Logo',
  githubAppLogin: 'ntt',
  githubAppName: 'Code Guardian™',
}

type UnknownData = {
  name: 'unknown'
  logoIconHref: '/0-Icon-Trimmed.png'
  logoIconAlt: 'Pixee Logo'
  githubAppLogin: 'pixeebot'
  githubAppName: 'Pixeebot'
}

const unknownData: UnknownData = {
  name: 'unknown',
  logoIconHref: '/0-Icon-Trimmed.png',
  logoIconAlt: 'Pixee Logo',
  githubAppLogin: 'pixeebot',
  githubAppName: 'Pixeebot',
}

let getHostname = () => window.location.hostname
let isDebug = () => false

export const getEnvironmentData = (getHostname: () => string, isDebug: () => boolean) => {
  const hostname = getHostname()

  if (hostname === 'localhost') {
    if (isDebug()) console.log('Local Dev environment detected')
    return localDevData
  }

  if (hostname === 'app.pixee.ai') {
    if (isDebug()) console.log('Pixee SaaS environment detected')
    return pixeeSaaSData
  }

  if (hostname === 'ntt.pixee.ai') {
    if (isDebug()) console.log('NTT environment detected')
    return nttData
  }

  return unknownData
}

export const useEnvironmentData = (): LocalDevData | PixeeSaaSData | NttData | UnknownData => {
  const [environmentData, setEnvironmentData] = useState<LocalDevData | PixeeSaaSData | NttData | UnknownData>(
    unknownData
  )

  useEffect(() => {
    setEnvironmentData(getEnvironmentData(getHostname, isDebug))
  }, [getHostname, isDebug])

  return environmentData
}

// Expose setHostnameGetter on the global window object if it exists
if (typeof window !== 'undefined') {
  ;(window as any).setHostnameGetter = (fn: () => string) => {
    getHostname = fn
  }
  ;(window as any).setIsDebug = (fn: () => boolean) => {
    isDebug = fn
  }
}
