import classNames from 'classnames'
import { ScanAnalysis, Tool } from '../utils/api-client/user-platform-api-schemas'
import * as styles from './composition-bar.css'

type TriageCompositionBarProps = {
  fixes: number
  total: number
  falsePositives: number
  truePositives: number
  suspicious: number
  wontFix: number
  tool: Tool
  analysisState: ScanAnalysis['current_state']['state']
}

export const TriageCompositionBar = ({
  fixes,
  total,
  falsePositives,
  truePositives,
  suspicious,
  wontFix,
  tool,
  analysisState,
}: TriageCompositionBarProps) => {
  const notTriaged = total - truePositives - suspicious - falsePositives - wontFix
  const {
    truePositivesPercentage,
    suspiciousPercentage,
    falsePositivesPercentage,
    wontFixPercentage,
    notTriagedPercentage,
  } = calculateLabelPercentages({
    total,
    truePositives,
    suspicious,
    falsePositives,
    wontFix,
    notTriaged,
  })
  if (analysisState === 'queued')
    return <div className={styles.baseAnalysis} role="status" aria-label="Analysis queued" />
  if (analysisState === 'in_progress')
    return (
      <div className={styles.baseAnalysis} role="status" aria-label="Analysis in progress">
        <div className={styles.inProgressSquare} />
      </div>
    )
  if (analysisState === 'skipped')
    return <div className={styles.baseAnalysis} role="status" aria-label="Analysis skipped" />
  if (analysisState === 'failed')
    return <div className={styles.baseAnalysis} role="status" aria-label="Analysis failed" />
  if (total === 0) return <div className={styles.baseAnalysis} role="status" aria-label="No findings" />

  return (
    <div className={classNames([styles.container])}>
      <div
        role="img"
        className={styles.barContainer}
        aria-label={`${fixes} out of ${total} findings had available fixes`}
      >
        <div
          className={classNames([styles.truePositives, styles.toolColorVariants[tool]])}
          style={{ width: truePositives === 0 ? '4px' : `${(truePositives / total) * 100}%` }}
        />
        <div
          className={classNames([styles.suspicious, styles.toolColorVariants[tool]])}
          style={{ width: suspicious === 0 ? '4px' : `${(suspicious / total) * 100}%` }}
        />
        <div
          className={classNames([styles.falsePositives, styles.toolColorVariants[tool]])}
          style={{ width: falsePositives === 0 ? '4px' : `${(falsePositives / total) * 100}%` }}
        />
        <div
          className={classNames([styles.wontFix, styles.toolColorVariants[tool]])}
          style={{ width: wontFix === 0 ? '4px' : `${(wontFix / total) * 100}%` }}
        />
        <svg
          className={classNames([styles.noAnalysis])}
          width={notTriaged === 0 ? '4px' : `${(notTriaged / total) * 100}%`}
          height="48"
          viewBox="0 0 100 48"
          fill="none"
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0 H92 Q100 0 100 8 V40 Q100 48 92 48 H0 V0"
            strokeWidth="1"
            strokeDasharray="6 6"
            fill="none"
            vectorEffect="non-scaling-stroke"
          />
        </svg>
      </div>
      <div className={styles.labels}>
        <div className={styles.label}>
          <span className={classNames([styles.truePositivesIcon, styles.toolColorVariants[tool]])} />
          <div>
            <label htmlFor="true-positives-count" className={styles.labelText}>
              True positives ({truePositivesPercentage})
            </label>
            <div className={styles.labelCount}>
              <output id="true-positives-count">{truePositives}</output>
              <span> findings confirmed</span>
            </div>
          </div>
        </div>
        <div className={styles.label}>
          <span className={classNames([styles.suspiciousIcon, styles.toolColorVariants[tool]])} />
          <div>
            <label htmlFor="suspicious-count" className={styles.labelText}>
              Suspicious ({suspiciousPercentage})
            </label>
            <div className={styles.labelCount}>
              <output id="suspicious-count">{suspicious}</output>
              <span> findings questionable</span>
            </div>
          </div>
        </div>
        <div className={styles.label}>
          <span className={classNames([styles.falsePositivesIcon, styles.toolColorVariants[tool]])} />
          <div>
            <label htmlFor="false-positives-count" className={styles.labelText}>
              False positives ({falsePositivesPercentage})
            </label>
            <div className={styles.labelCount}>
              <output id="false-positives-count">{falsePositives}</output>
              <span> findings dismissed</span>
            </div>
          </div>
        </div>
        <div className={styles.label}>
          <span className={classNames([styles.wontFixIcon, styles.toolColorVariants[tool]])} />
          <div>
            <label htmlFor="wont-fix-count" className={styles.labelText}>
              Won't fix ({wontFixPercentage})
            </label>
            <div className={styles.labelCount}>
              <output id="wont-fix-count">{wontFix}</output>
              <span> findings negligible</span>
            </div>
          </div>
        </div>
        <div className={styles.label}>
          <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="11" strokeWidth="2" strokeDasharray="6 6" className={styles.noAnalysisIcon} />
          </svg>
          <div>
            <label htmlFor="not-triaged-count" className={styles.labelText}>
              No analysis ({notTriagedPercentage})
            </label>
            <div className={styles.labelCount}>
              <output id="not-triaged-count">{notTriaged}</output>
              <span> findings not triaged</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const calculateLabelPercentages = ({
  total,
  truePositives,
  suspicious,
  falsePositives,
  wontFix,
  notTriaged,
}: {
  total: number
  truePositives: number
  suspicious: number
  falsePositives: number
  wontFix: number
  notTriaged: number
}) => {
  if (total === 0) {
    return {
      truePositivesPercentage: '0%',
      suspiciousPercentage: '0%',
      falsePositivesPercentage: '0%',
      wontFixPercentage: '0%',
      notTriagedPercentage: '0%',
    }
  }

  const truePositivesPercentage = `${Math.round((truePositives / total) * 100)}%`
  const suspiciousPercentage = `${Math.round((suspicious / total) * 100)}%`
  const falsePositivesPercentage = `${Math.round((falsePositives / total) * 100)}%`
  const wontFixPercentage = `${Math.round((wontFix / total) * 100)}%`
  const notTriagedPercentage = `${Math.round((notTriaged / total) * 100)}%`

  return {
    truePositivesPercentage,
    suspiciousPercentage,
    falsePositivesPercentage,
    wontFixPercentage,
    notTriagedPercentage,
  }
}
