import { InfoCircledIcon } from '@radix-ui/react-icons'
import * as RadixTooltip from '@radix-ui/react-tooltip'
import classNames from 'classnames'
import { useState } from 'react'
import * as styles from './tooltip.css.ts'

interface TooltipProps {
  children: React.ReactNode
  iconClassName?: string
  side?: 'top' | 'right' | 'bottom' | 'left'
  width?: number
}

// Necessary to enable tooltip on click on mobile since Radix designers won't add this functionality
// https://github.com/radix-ui/primitives/issues/955
function TooltipWithClickToOpen({ children, iconClassName, side = 'top', width }: TooltipProps) {
  const [open, setOpen] = useState(false)

  return (
    <RadixTooltip.Provider>
      <RadixTooltip.Root open={open} delayDuration={0} onOpenChange={setOpen}>
        <RadixTooltip.Trigger asChild>
          <span
            onClick={() => setOpen(true)}
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <InfoCircledIcon className={classNames(styles.infoIcon, iconClassName)} />
          </span>
        </RadixTooltip.Trigger>
        <RadixTooltip.Portal>
          <RadixTooltip.Content className={styles.tooltip} sideOffset={3} side={side}>
            <div className={styles.tooltipContent} style={width ? { width: `${width}px` } : { maxWidth: '300px' }}>
              {children}
            </div>
          </RadixTooltip.Content>
        </RadixTooltip.Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  )
}

interface TooltipWithTriggerProps {
  children: React.ReactNode
  trigger: React.ReactNode
  size?: 'small' | 'normal'
  side?: 'top' | 'right' | 'bottom' | 'left'
  width?: number
}

function TooltipWithHover({ children, trigger, side = 'top', width, size = 'normal' }: TooltipWithTriggerProps) {
  return (
    <RadixTooltip.Provider>
      <RadixTooltip.Root delayDuration={0}>
        <RadixTooltip.Trigger asChild>{trigger}</RadixTooltip.Trigger>
        <RadixTooltip.Portal>
          <RadixTooltip.Content
            className={classNames(styles.tooltip, {
              [styles.smallTooltip]: size === 'small',
            })}
            sideOffset={3}
            side={side}
            asChild
          >
            <div className={styles.tooltipContent} style={width ? { width: `${width}px` } : { maxWidth: '300px' }}>
              {children}
            </div>
          </RadixTooltip.Content>
        </RadixTooltip.Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  )
}

export { TooltipWithClickToOpen as Tooltip, TooltipWithHover }
