import { match } from 'ts-pattern'
import { Severity } from '../utils/api-client/user-platform-api-schemas.ts'
import * as styles from './severity-badge.css.ts'

type SeverityBadgeProps = {
  severity: Severity | null
  style?: any
}

export function SeverityBadge({ severity, ...props }: SeverityBadgeProps) {
  const text = match(severity)
    .with(null, () => 'Unknown')
    .otherwise(() => severity?.label ?? 'Unknown')

  const color = match(severity?.rank)
    .with(undefined, () => 'unknown')
    .with(0, () => 'unknown')
    .when(
      n => n >= 0 && n <= 25,
      () => 'low'
    )
    .when(
      n => n > 25 && n <= 50,
      () => 'medium'
    )
    .when(
      n => n > 50 && n <= 75,
      () => 'high'
    )
    .when(
      n => n > 75,
      () => 'critical'
    )
    .otherwise(() => 'unknown')

  return (
    <span className={styles.badge[color]} data-severity={color} {...props}>
      {capitalize(text)}
    </span>
  )
}

const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1)
