import * as Dialog from '@radix-ui/react-dialog'
import { Cross2Icon } from '@radix-ui/react-icons'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { z } from 'zod'
import { Routes } from '../../routes.ts'
import { mapToolToLogoAndName } from '../../routes/analysis-details-page'
import { usePostAnalyzeScan, usePostUploadScan } from '../../utils/api-client/user-platform-api-hooks'
import { CreateScanRequestFormData, Tool } from '../../utils/api-client/user-platform-api-schemas'
import { useAddToast } from '../../utils/higher-order-components/with-toasts'
import { DefaultButton } from '../default-button'
import { SelectBox } from '../select-box'
import * as styles from './upload-scan-form-drawer.css.ts'

interface UploadScanFormDrawerProps {
  repositoryId: string
  repositoryDisplayName: string
  handleCloseDrawer: () => void
}
const validTools: Tool[] = [
  Tool.Enum.APPSCAN,
  Tool.Enum.POLARIS,
  Tool.Enum.CHECKMARX,
  Tool.Enum.CODEQL,
  Tool.Enum.SEMGREP,
  Tool.Enum.SNYK,
  Tool.Enum.SONAR,
]
const tools: Tool[] = Tool.options.filter(tool => validTools.includes(tool as Tool))

export const UploadScanFormDrawer: React.FC<UploadScanFormDrawerProps> = ({
  repositoryId,
  repositoryDisplayName,
  handleCloseDrawer,
}) => {
  const navigate = useNavigate()
  const [selectedTool, setSelectedTool] = useState('')
  const [fileName, setFileName] = useState('')
  const [commitHash, setCommitHash] = useState('')

  const analyzeScanMutation = usePostAnalyzeScan({
    onError: (error: any) => {
      handleAddToastWithTimeout({
        message: <>Analysis trigger failed. You can try to analyze it again later. {error.message}.</>,
        variant: 'error',
      })

      if (error.bodyAsText && typeof error.bodyAsText === 'string') {
        handleAddToastWithTimeout({
          message: <pre style={{ margin: '0px' }}>{error.bodyAsText}</pre>,
          variant: 'error',
        })
      }
    },
  })
  const uploadScanMutation = usePostUploadScan({
    onSuccess: ({ uuid: scanId }) => {
      handleAddToastWithTimeout({
        message: <>Scan uploaded successfully.</>,
        variant: 'success',
      })

      analyzeScanMutation.mutate(
        { scanId },
        {
          onSuccess: ({ id: analysisId }) => navigate(Routes.AnalysisDetailsPage.createPath({ analysisId })),
        }
      )
    },
    onError: (error: any) => {
      handleAddToastWithTimeout({
        message: <>{`Unable to upload scan: ${error.message}. Please try again.`}</>,
        variant: 'error',
      })

      if (error.bodyAsText && typeof error.bodyAsText === 'string') {
        handleAddToastWithTimeout({
          message: <pre style={{ margin: '0px' }}>{error.bodyAsText}</pre>,
          variant: 'error',
        })
      }
    },
  })

  const { handleAddToastWithTimeout } = useAddToast()

  const handleCommitHashChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setCommitHash(event.target.value)
  }, [])

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      const draftFormData = extractData(new FormData(event.currentTarget))
      const { repositoryId, ...verifiedData } = verifyData(draftFormData)
      uploadScanMutation.mutate({ repositoryId, body: verifiedData })
    },
    [uploadScanMutation, selectedTool, repositoryId]
  )

  const handleFileChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setFileName(event.target.files?.[0]?.name || '')
    } else {
      setFileName('')
    }
  }, [])

  const handleSelectChange = useCallback((value: string) => {
    setSelectedTool(value)
  }, [])

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLLabelElement>) => {
      if (event.key === 'Enter' || event.key === ' ') {
        event.preventDefault()
        document.getElementById(`file-${repositoryId}`)?.click()
      }
    },
    [repositoryId]
  )

  const handleClearFile = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation()
      setFileName('')
      const fileInput = document.getElementById(`file-${repositoryId}`) as HTMLInputElement
      if (fileInput) {
        fileInput.value = ''
      }
    },
    [repositoryId]
  )

  return (
    <Dialog.Root open={true} modal={false}>
      <Dialog.Portal>
        <Dialog.Content
          className={styles.content}
          onEscapeKeyDown={event => event.preventDefault()}
          onPointerDownOutside={event => event.preventDefault()}
          onInteractOutside={event => event.preventDefault()}
        >
          <Dialog.Title className={styles.title}>Upload scan results</Dialog.Title>
          <Dialog.Description className={styles.description}>
            Upload SAST tool results for Pixee to analyze
          </Dialog.Description>

          <form onSubmit={handleSubmit} className={styles.formContainer}>
            <div className={styles.flexContainer}>
              <input type="hidden" name="repositoryId" value={repositoryId} />
              <label htmlFor={`repository-${repositoryId}`} className={styles.inputLabel}>
                Repository
              </label>
              <input
                type="text"
                id={`repository-${repositoryId}`}
                name="repository"
                disabled
                defaultValue={repositoryDisplayName}
                className={styles.inputFieldBold}
              />
            </div>

            <div className={styles.flexContainer}>
              <SelectBox
                label="Tool"
                name="tool"
                value={selectedTool}
                onValueChange={handleSelectChange}
                placeholder="Select a tool"
                options={tools
                  .filter(tool => tool != 'PIXEE')
                  .map(tool => {
                    const { name, logoHref } = mapToolToLogoAndName(tool, 'dark')
                    return {
                      value: tool,
                      label: (
                        <span className={styles.selectItemText}>
                          <img
                            src={logoHref}
                            alt={`${name} logo`}
                            width="16"
                            height="16"
                            style={{ marginRight: '8px' }}
                          />
                          {name}
                        </span>
                      ),
                    }
                  })}
              />
            </div>

            <div className={styles.flexContainer}>
              <input type="file" id={`file-${repositoryId}`} name="file" hidden onChange={handleFileChange} />
              <label htmlFor={`file-${repositoryId}`} className={styles.inputLabel}>
                File
              </label>
              <label
                htmlFor={`file-${repositoryId}`}
                className={styles.fileInputField}
                tabIndex={0}
                onKeyDown={handleKeyDown}
              >
                {fileName ? (
                  <>
                    {fileName}
                    <Cross2Icon className={styles.closeIcon} onClick={handleClearFile} />
                  </>
                ) : (
                  'Select...'
                )}
              </label>
              <span className={styles.inputTrailer}>Results are in SARIF format. Example: cx_results.sarif</span>
            </div>

            <div className={styles.flexContainer}>
              <label htmlFor={`gitCommitHash-${repositoryId}`} className={styles.inputLabel}>
                Git commit hash (optional)
              </label>
              <input
                type="text"
                id={`gitCommitHash-${repositoryId}`}
                name="gitCommitHash"
                className={styles.inputField}
                placeholder="Ex: a1b2c3d4e5f67890abcdef1234567890abcdef12"
                value={commitHash}
                onChange={handleCommitHashChange}
              />
            </div>

            <div className={styles.uploadButtonContainer}>
              <DefaultButton
                buttonType="primary"
                buttonRole="submit"
                state={!selectedTool || !fileName ? 'disabled' : uploadScanMutation.isPending ? 'loading' : 'default'}
              >
                Upload
              </DefaultButton>
            </div>
          </form>

          <Dialog.Close asChild className={styles.closeButton}>
            <button aria-label="Close" onClick={handleCloseDrawer}>
              <Cross2Icon className={styles.closeButtonIcon} />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

const extractData = (formData: FormData): any => {
  const data: any = {}
  formData.forEach((value, key) => {
    if (value instanceof File) {
      data[key] = value
    } else {
      data[key] = value.toString()
    }
  })
  return data
}

const verifyData = (data: any): CreateScanRequestFormData & { repositoryId: string } =>
  CreateScanRequestFormData.extend({
    repositoryId: z.string(),
  }).parse({
    repositoryId: data.repositoryId,
    metadata: {
      tool: (data.tool as string).toLowerCase(),
      sha: data.gitCommitHash,
    },
    files: {
      [data.file.name]: data.file,
    },
  })
