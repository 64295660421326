export const Routes = {
  LoginPage: { path: '/login', params: [] },
  IntroPage: { path: '/intro', params: [] },
  WelcomePage: { path: '/welcome', params: [] },
  RepositoriesPage: { path: '/repositories', params: [] },
  HomePage: { path: '/', params: [] },
  AnalysisDetailsPageDeprecated: {
    path: '/analysis/:analysisId/:scanId',
    createPath: (analysisId: string, scanId: string) => `/analysis/${analysisId}/${scanId}`,
    params: [':analysisId', ':scanId'],
  },
  AnalysisDetailsPage: {
    path: '/analysis/:analysisId',
    createPath: ({ analysisId, queryString }: { analysisId: string; queryString?: string }) =>
      `/analysis/${analysisId}${queryString ? `?${queryString}` : ''}`,
    params: [':analysisId'],
  },
  AnalysisDetailsPageFindingFixDrawer: {
    path: '/analysis/:analysisId/fix/:findingId',
    createPath: ({
      analysisId,
      findingId,
      queryString,
    }: {
      analysisId: string
      findingId: string
      queryString?: string
    }) => `/analysis/${analysisId}/fix/${findingId}${queryString ? `?${queryString}` : ''}`,
    params: [':analysisId', ':findingId'],
  },
  ScansOverviewPage: {
    path: '/scans/',
  },
} as const

export type RouteKey = keyof typeof Routes
