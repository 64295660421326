import { GitMergeIcon, MarkGithubIcon } from '@primer/octicons-react'
import { ExternalLinkIcon } from '@radix-ui/react-icons'
import { useLocalStorage } from '@uidotdev/usehooks'
import { DateTime } from 'luxon'
import { useCallback, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import invariant from 'tiny-invariant'
import { match } from 'ts-pattern'
import { AnalysisStatusBadge } from '../components/analysis-status-badge.tsx'
import { BranchName } from '../components/branch-name.tsx'
import { TriageCompositionBar } from '../components/composition-bar.tsx'
import { FindingsTablePure } from '../components/findings-table.tsx'
import { Spinner } from '../components/spinner'
import AzureLightIcon from '../components/svg/azure-light.svg?react'
import GitlabIcon from '../components/svg/gitlab-icon.svg?react'
import { Pagination } from '../components/table'
import { TooltipWithHover } from '../components/tooltip'
import { utilities } from '../main.css.ts'
import { Routes } from '../routes'
import {
  useGetChangesetsForFix,
  useGetFindingArticle,
  useGetFindings,
  useGetFixesForFinding,
  useGetScanAnalysisV1,
  usePostPatch,
} from '../utils/api-client/user-platform-api-hooks'
import {
  Changeset,
  Finding,
  Fix,
  PaginatedFindings,
  RepositoryType,
  ScanAnalysis,
  Tool,
} from '../utils/api-client/user-platform-api-schemas'
import { useTheme } from '../utils/higher-order-components/with-theme'
import { useAddToast } from '../utils/higher-order-components/with-toasts'
import * as styles from './analysis-details-page.css'

export function AnalysisDetailsPage() {
  const { analysisId, findingId: findingIdParam } = useParams()
  const location = useLocation()

  if (!analysisId) {
    throw new Error('Missing required parameter: analysisId')
  }

  if (location.pathname.includes('/fix/') && !findingIdParam) {
    throw new Error('Missing required parameter for fix route: findingIdParam')
  }

  useRedirectFromOldAnalysisDetailsPageRoute(analysisId)

  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { theme } = useTheme()
  const { handleAddToastWithTimeout } = useAddToast()
  const { data: scanAnalysis } = useGetScanAnalysisV1({
    analysisId,
  })

  const DEFAULT_PAGE_INDEX = 0
  const DEFAULT_PAGE_SIZE = 10
  const VALID_PAGE_SIZES = [10, 25, 50]
  const pageIndex = Number(searchParams.get('page_number') ?? DEFAULT_PAGE_INDEX)
  const pageSize = Number(searchParams.get('page_size') ?? DEFAULT_PAGE_SIZE)
  const pagination = { pageIndex, pageSize }

  const handlePaginationWithTotal = (pagination: Pagination) => {
    if (pagination.pageIndex === DEFAULT_PAGE_INDEX && pagination.pageSize === DEFAULT_PAGE_SIZE) {
      navigate('')
      return
    }

    let params = new URLSearchParams({
      page_number: pagination.pageIndex.toString(),
      page_size: pagination.pageSize.toString(),
    })

    if (totalResults > 0) {
      const isPageSizeValid = VALID_PAGE_SIZES.includes(pagination.pageSize)
      const isPageIndexValid = pagination.pageIndex * pagination.pageSize < totalResults

      if (!isPageSizeValid && !isPageIndexValid) {
        params = new URLSearchParams({
          page_number: DEFAULT_PAGE_INDEX.toString(),
          page_size: DEFAULT_PAGE_SIZE.toString(),
        })
      } else if (!isPageSizeValid) {
        params = new URLSearchParams({
          page_number: pagination.pageIndex.toString(),
          page_size: DEFAULT_PAGE_SIZE.toString(),
        })
      } else if (!isPageIndexValid) {
        params = new URLSearchParams({
          page_number: DEFAULT_PAGE_INDEX.toString(),
          page_size: pagination.pageSize.toString(),
        })
      }
    }

    navigate(`?${params.toString()}`)
  }

  const [hasFix, setHasFix] = useLocalStorage<boolean>('ui-state/hasFix', false)
  const [hasNoFix, setHasNoFix] = useLocalStorage<boolean>('ui-state/hasNoFix', false)
  const handleToggleHasFix = () => setHasFix(previous => !previous)
  const handleToggleHasNoFix = () => setHasNoFix(previous => !previous)
  const { data: findingsPage } = useGetFindings({
    analysisId,
    pageNumber: pagination.pageIndex,
    pageSize: pagination.pageSize,
    hasFix,
    hasNoFix,
  })
  const findings = findingsPage?._embedded?.items
  const totalResults = findingsPage?.total ?? 0

  const [selectedFindingId, setSelectedFindingId] = useState<string | undefined>(undefined)
  const {
    data: findingArticle,
    error,
    isError: isFindingArticleError,
  } = useGetFindingArticle({
    analysisId,
    findingId: selectedFindingId!,
    enabled: !!selectedFindingId,
  })
  const selectedFinding = findings?.find(finding => finding.id === selectedFindingId)
  const selectedFindingMarkdown = findingArticle
  useEffect(() => {
    if (isFindingArticleError) {
      setSelectedFindingId(undefined)
      handleAddToastWithTimeout({
        message: <p>Failed loading finding triage article: {error?.message ?? 'Unknown error'}</p>,
        variant: 'error',
      })
      if (error.bodyAsText) {
        handleAddToastWithTimeout({
          message: <pre>{error.bodyAsText}</pre>,
          variant: 'error',
        })
      }
    }
  }, [isFindingArticleError, error, handleAddToastWithTimeout])

  const { data: paginatedFixes } = useGetFixesForFinding({
    analysisId,
    findingId: findingIdParam!,
    enabled: location.pathname.includes('/fix/') && !!findingIdParam,
  })
  const fixes = paginatedFixes?._embedded?.items
  const fix = fixes?.[0]

  const { data: paginatedChangesets } = useGetChangesetsForFix({
    fixId: fix?.id!,
    enabled: !!fix,
  })
  const changesets = paginatedChangesets?._embedded?.items

  const postPatchMutation = usePostPatch({
    onSuccess: () => {
      handleAddToastWithTimeout({
        message: <>Request to send patch received successfully!</>,
        variant: 'success',
      })
      navigate(Routes.AnalysisDetailsPage.createPath({ analysisId, queryString: searchParams.toString() }), {
        replace: true,
      })
    },
    onError: () => {
      handleAddToastWithTimeout({
        message: <>Failed to send patch.</>,
        variant: 'error',
      })
    },
  })

  const handleSetSelectedFindingIdForFix = useCallback(
    (findingId: string | undefined) => {
      const currentQueryString = window.location.search.slice(1)

      const navigateTo =
        findingId === undefined
          ? Routes.AnalysisDetailsPage.createPath({ analysisId, queryString: currentQueryString })
          : Routes.AnalysisDetailsPageFindingFixDrawer.createPath({
              analysisId,
              findingId,
              queryString: currentQueryString,
            })

      navigate(navigateTo, { replace: true })
    },
    [analysisId, navigate]
  )

  const handleSendPatch = useCallback(
    () =>
      postPatchMutation.mutate({
        analysisId,
        createPatchRequest: {
          findings: [findingIdParam!],
        },
      }),
    [analysisId, findingIdParam, postPatchMutation]
  )

  return (
    <AnalysisDetailsPagePure
      scanAnalysis={scanAnalysis}
      paginatedFindings={findingsPage}
      pagination={pagination}
      handlePaginationChange={handlePaginationWithTotal}
      selectedFinding={selectedFinding}
      selectedFindingMarkdown={selectedFindingMarkdown}
      setSelectedFindingId={setSelectedFindingId}
      selectedFindingIdForFix={findingIdParam}
      handleSetSelectedFindingIdForFix={handleSetSelectedFindingIdForFix}
      fixes={fixes}
      changesets={changesets}
      handleSendPatch={handleSendPatch}
      isSendPatchLoading={postPatchMutation.isPending}
      theme={theme}
      handleToggleHasFix={handleToggleHasFix}
      handleToggleHasNoFix={handleToggleHasNoFix}
      hasFix={hasFix}
      hasNoFix={hasNoFix}
    />
  )
}

export function AnalysisDetailsPagePure({
  scanAnalysis,
  paginatedFindings,
  pagination,
  handlePaginationChange = () => {},
  selectedFinding = undefined,
  selectedFindingMarkdown = '',
  setSelectedFindingId = () => {},
  selectedFindingIdForFix = undefined,
  handleSetSelectedFindingIdForFix = () => {},
  fixes = [],
  changesets = [],
  handleSendPatch,
  isSendPatchLoading,
  theme = 'dark',
  handleToggleHasFix = () => {},
  handleToggleHasNoFix = () => {},
  hasFix = false,
  hasNoFix = false,
}: {
  scanAnalysis?: ScanAnalysis
  paginatedFindings?: PaginatedFindings
  pagination?: Pagination
  handlePaginationChange?: (pagination: Pagination) => void
  selectedFinding?: Finding
  selectedFindingMarkdown?: string
  setSelectedFindingId?: React.Dispatch<React.SetStateAction<string | undefined>>
  selectedFindingIdForFix?: string | undefined
  handleSetSelectedFindingIdForFix?: (findingId: string | undefined) => void
  fixes?: Fix[]
  changesets?: Changeset[]
  handleSendPatch?: () => void
  isSendPatchLoading?: boolean
  theme?: ReturnType<typeof useTheme>['theme']
  handleToggleHasFix?: () => void
  handleToggleHasNoFix?: () => void
  hasFix?: boolean
  hasNoFix?: boolean
}) {
  return (
    <div className={styles.analysisDetailsContainer}>
      <nav aria-label="Breadcrumb to All Scans">
        <BreadcrumbContent />
      </nav>
      <header className={styles.analysisDetailsHeader} role="banner">
        <ScanInfoSection scanAnalysis={scanAnalysis} theme={theme} />
        <ScanCompositionSection scanAnalysis={scanAnalysis} paginatedFindings={paginatedFindings} />
        <MetricsSection scanAnalysis={scanAnalysis} paginatedFindings={paginatedFindings} theme={theme} />
      </header>
      <FindingsTablePure
        paginatedFindings={paginatedFindings}
        pagination={pagination}
        handlePaginationChange={handlePaginationChange}
        selectedFinding={selectedFinding}
        selectedFindingMarkdown={selectedFindingMarkdown}
        setSelectedFindingId={setSelectedFindingId}
        selectedFindingIdForFix={selectedFindingIdForFix}
        handleSetSelectedFindingIdForFix={handleSetSelectedFindingIdForFix}
        selectedFindingFixes={fixes}
        selectedFindingChangesets={changesets}
        theme={theme}
        handleSendPatch={handleSendPatch}
        isSendPatchLoading={isSendPatchLoading}
        handleToggleHasFix={handleToggleHasFix}
        handleToggleHasNoFix={handleToggleHasNoFix}
        hasFix={hasFix}
        hasNoFix={hasNoFix}
      />
    </div>
  )
}

function BreadcrumbContent() {
  return (
    <div className={styles.breadcrumbContainer}>
      <Link to={Routes.ScansOverviewPage.path} className={styles.breadcrumbLink}>
        All scans
      </Link>{' '}
      <span className={styles.breadcrumbSeparator}>/ Scan analysis</span>
    </div>
  )
}

function ScanInfoSection({ scanAnalysis, theme }: { scanAnalysis?: ScanAnalysis; theme: 'light' | 'dark' }) {
  return (
    <section className={styles.scanInfoContainer}>
      {scanAnalysis ? (
        <ScanInfo scanAnalysis={scanAnalysis} theme={theme} />
      ) : (
        <Spinner label="Loading scan analysis..." />
      )}
    </section>
  )
}

function ScanCompositionSection({
  scanAnalysis,
  paginatedFindings,
}: {
  scanAnalysis?: ScanAnalysis
  paginatedFindings?: PaginatedFindings
}) {
  return (
    <section className={styles.scanCompositionContainer} aria-labelledby="scan-composition-header">
      <h2 id="scan-composition-header" className={utilities.visuallyHidden}>
        Scan Composition
      </h2>
      {scanAnalysis?._embedded?.scan?._links?.repository?.title && (
        <RepositoryInformation
          type={'git'}
          displayRepositoryName={repositoryNameToDisplay('git')(scanAnalysis._embedded.scan._links.repository.title)}
          displayRepositoryIcon={repositoryIconToDisplay('git')}
          analysisState={scanAnalysis.current_state.state}
        />
      )}
      {scanAnalysis?._embedded?.scan?.detector && paginatedFindings ? (
        <TriageCompositionBar
          fixes={paginatedFindings.fixes}
          total={paginatedFindings.total}
          falsePositives={paginatedFindings.false_positives}
          truePositives={paginatedFindings.true_positives}
          suspicious={paginatedFindings.suspicious}
          wontFix={paginatedFindings.wont_fix}
          tool={scanAnalysis._embedded.scan.detector}
          analysisState={scanAnalysis.current_state.state}
        />
      ) : (
        <Spinner label="Loading scan details and findings." />
      )}
    </section>
  )
}

function MetricsSection({
  scanAnalysis,
  paginatedFindings,
  theme,
}: {
  scanAnalysis?: ScanAnalysis
  paginatedFindings?: PaginatedFindings
  theme: 'light' | 'dark'
}) {
  const analysisState = scanAnalysis?.current_state?.state
  const shouldShowMetrics =
    (analysisState === 'completed_results' || analysisState === 'completed_no_results') && paginatedFindings
  const totalFindings = paginatedFindings?.total ?? 0
  const totalTriaged =
    (paginatedFindings?.true_positives ?? 0) +
    (paginatedFindings?.suspicious ?? 0) +
    (paginatedFindings?.wont_fix ?? 0) +
    (paginatedFindings?.false_positives ?? 0)
  const totalFixes = paginatedFindings?.fixes ?? 0

  const fixCoverageValue = match(totalFindings)
    .with(0, () => {
      return '0%'
    })
    .otherwise(() => {
      return `${Math.round((totalFixes / totalFindings) * 100)}%`
    })

  const triagedValue = match(totalFindings)
    .with(0, () => {
      return '0%'
    })
    .otherwise(() => {
      return `${Math.round((totalTriaged / totalFindings) * 100)}%`
    })

  return (
    <section className={styles.metricsSection} role="complementary" aria-labelledby="metrics-header">
      <h2 id="metrics-header" className={utilities.visuallyHidden}>
        Metrics and External Link
      </h2>
      {scanAnalysis?._embedded?.scan?.html_url && (
        <a
          href={scanAnalysis._embedded.scan.html_url}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.toolLink}
        >
          View in {mapToolToLogoAndName(scanAnalysis._embedded.scan.detector, theme).name}
          <ExternalLinkIcon className={styles.externalLinkIcon} />
        </a>
      )}
      <div className={styles.metricsContainer} role="group" aria-label="Findings Metrics">
        <output id="findings-count" className={styles.metricValue}>
          {shouldShowMetrics ? totalFindings : '-'}
        </output>
        <label htmlFor="findings-count" className={styles.metricLabel}>
          findings
        </label>
        <output id="triaged" className={styles.metricValue}>
          {shouldShowMetrics ? triagedValue : '-'}
        </output>
        <label htmlFor="triaged" className={styles.metricLabel}>
          triaged
        </label>
        <output id="fix-coverage" className={styles.metricValue}>
          {shouldShowMetrics ? fixCoverageValue : '-'}
        </output>
        <label htmlFor="fix-coverage" className={styles.metricLabel}>
          fix coverage
        </label>
      </div>
    </section>
  )
}

export const mapToolToLogoAndName = (tool: Tool, mode: 'light' | 'dark') => {
  const suffix = mode === 'light' ? 'Lt' : 'Dk'
  return match(tool)
    .with('APPSCAN', () => ({ name: 'AppScan', logoHref: '/SastToolVisualAssets/AppScan-Icon.png' }))
    .with('POLARIS', () => ({ name: 'Black Duck Polaris', logoHref: `/SastToolVisualAssets/Polaris-Icon.svg` }))
    .with('CHECKMARX', () => ({ name: 'Checkmarx', logoHref: '/SastToolVisualAssets/Checkmarx-Icon.svg' }))
    .with('CODEQL', () => ({ name: 'CodeQL', logoHref: '/SastToolVisualAssets/CodeQL-Logo.svg' }))
    .with('CONTRAST', () => ({ name: 'Contrast', logoHref: `/SastToolVisualAssets/Contrast-Icon-${suffix}.svg` }))
    .with('DEFECT_DOJO', () => ({ name: 'DefectDojo', logoHref: '/SastToolVisualAssets/DefectDojo-Icon.svg' }))
    .with('PIXEE', () => ({ name: 'Pixee', logoHref: '/SastToolVisualAssets/Pixee-Icon.svg' }))
    .with('SEMGREP', () => ({ name: 'Semgrep', logoHref: '/SastToolVisualAssets/Semgrep-Icon.svg' }))
    .with('SNYK', () => ({ name: 'Snyk', logoHref: `/SastToolVisualAssets/Snyk-Icon-${suffix}.svg` }))
    .with('SONAR', () => ({ name: 'SonarQube', logoHref: `/SastToolVisualAssets/Sonar-Icon-${suffix}.svg` }))
    .exhaustive()
}

const ScanInfo = ({ scanAnalysis, theme }: { scanAnalysis: ScanAnalysis; theme: 'light' | 'dark' }) => {
  const { name, logoHref } = mapToolToLogoAndName(scanAnalysis._embedded.scan.detector, theme)
  const scanDate = DateTime.fromISO(scanAnalysis._embedded.scan.imported_at)

  return (
    <>
      <h2 className={utilities.visuallyHidden}>Scan Information</h2>
      <div className={styles.toolLogoContainer}>
        <img src={logoHref} alt={`${name} logo`} className={styles.toolLogo} />
      </div>
      <div className={styles.dateContainer}>
        <p className={styles.dateText}>{scanDate.toFormat('M/d/yy')}</p>
        <p className={styles.timeText}>
          {scanDate.toFormat('hh:mm:ss a')} {scanDate.toFormat('ZZZZ')}
        </p>
      </div>
      {scanAnalysis.branch && <BranchName branch={scanAnalysis.branch} />}
    </>
  )
}

type repositoryDisplayName = { owner: string; name: string } | { name: string }
type DisplayRepositoryName = () => repositoryDisplayName
type RepositoryNameToDisplay = (repositoryName: string) => DisplayRepositoryName
type RepositoryNameToDisplayWithType = (type: RepositoryType) => RepositoryNameToDisplay
export const repositoryNameToDisplay: RepositoryNameToDisplayWithType = type => repositoryName => () => {
  if (type === 'github') {
    const [owner, name] = repositoryName.split('/')
    invariant(owner, 'Owner is required for GitHub repository names')
    invariant(name, 'Name is required for GitHub repository names')
    return { owner, name }
  }

  const name = repositoryName
  invariant(name, 'Name is required for generic Git repository names')
  return { name }
}

type DisplayRepositoryIcon = () =>
  | typeof AzureLightIcon
  | typeof MarkGithubIcon
  | typeof GitlabIcon
  | typeof GitMergeIcon
type RepositoryIconToDisplayWithType = (type: RepositoryType) => DisplayRepositoryIcon
export const repositoryIconToDisplay: RepositoryIconToDisplayWithType = type => () => {
  return match(type)
    .with('azure', () => () => <AzureLightIcon className={styles.scmIcon} />)
    .with('github', () => () => <MarkGithubIcon aria-label="GitHub icon" className={styles.scmIcon} />)
    .with('gitlab', () => () => <GitlabIcon aria-label="Gitlab icon" className={styles.scmIcon} />)
    .otherwise(() => () => <GitMergeIcon aria-label="Git icon" className={styles.scmIcon} />)
}

const RepositoryInformation: React.FC<{
  type: RepositoryType
  analysisState?: ScanAnalysis['current_state']['state']
  displayRepositoryName: DisplayRepositoryName
  displayRepositoryIcon: DisplayRepositoryIcon
}> = ({ type, analysisState, displayRepositoryName, displayRepositoryIcon }) => {
  const Icon = displayRepositoryIcon()
  const displayName = displayRepositoryName()
  const shouldTruncate = displayName.name.length > 85

  return (
    <>
      {'owner' in displayName && <p className={styles.accountLoginText}>{displayName.owner}</p>}
      <div className={styles.repositoryNameContainer}>
        <div className={styles.repositoryNameSection}>
          <Icon title={`${type} icon`} />
          {shouldTruncate ? (
            <TooltipWithHover
              trigger={<p className={styles.repositoryName}>{`${displayName.name.slice(0, 85)}...`}</p>}
            >
              {displayName.name}
            </TooltipWithHover>
          ) : (
            <p className={styles.repositoryName}>{displayName.name}</p>
          )}
        </div>
        {analysisState && analysisState !== 'completed_results' && analysisState !== 'completed_no_results' && (
          <AnalysisStatusBadge variant={analysisState} />
        )}
      </div>
    </>
  )
}

function useRedirectFromOldAnalysisDetailsPageRoute(analysisId: string) {
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    const scanId = location.pathname.split('/').pop()
    if (scanId && location.pathname.includes(`/analysis/${analysisId}/${scanId}`)) {
      navigate(Routes.AnalysisDetailsPage.createPath({ analysisId }), { replace: true })
    }
  }, [analysisId, location.pathname, navigate])
}
