import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import classNames from 'classnames'
import { Checkbox } from './checkbox.tsx'
import * as styles from './dropdown-filter.css.ts'
import { OutlineButton } from './outline-button.tsx'

type Filter = {
  id: string
  text: string
  value: boolean
  handleToggle: () => void
}

type FilterGroup = {
  id: string
  text: string
  filters: Filter[]
}

export function DropdownFilter({ filterGroups }: { filterGroups: FilterGroup[] }) {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <OutlineButton
          state={
            filterGroups.flatMap(filterGroup => filterGroup.filters).filter(filter => filter.value).length > 0
              ? 'active'
              : 'inactive'
          }
          size="small"
        >
          Filter ({filterGroups.flatMap(filterGroup => filterGroup.filters).filter(filter => filter.value).length})
        </OutlineButton>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className={classNames(styles.dropdownContainer, styles.disableOutlineHover)}
          onCloseAutoFocus={e => e.preventDefault()}
        >
          {filterGroups.map(filterGroup => (
            <DropdownMenu.Group className={styles.group} key={filterGroup.id}>
              <DropdownMenu.Label className={styles.label}>{filterGroup.text}</DropdownMenu.Label>
              {filterGroup.filters.map(filter => (
                <CheckboxItem
                  key={filter.id}
                  text={filter.text}
                  isChecked={() => filter.value}
                  toggleColumnFilter={filter.handleToggle}
                />
              ))}
            </DropdownMenu.Group>
          ))}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}

type CheckboxItemProps = {
  text: string
  isChecked: () => boolean
  toggleColumnFilter: Function
}

function CheckboxItem({ text, isChecked, toggleColumnFilter }: CheckboxItemProps) {
  return (
    <DropdownMenu.CheckboxItem
      className={styles.checkboxItem}
      onSelect={event => event.preventDefault()}
      checked={isChecked()}
      onCheckedChange={() => {
        toggleColumnFilter()
      }}
    >
      <Checkbox checked={isChecked()} Indicator={DropdownMenu.ItemIndicator} />
      <span className={styles.checkboxText}>{text}</span>
    </DropdownMenu.CheckboxItem>
  )
}
