import * as Sentry from '@sentry/react'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { ErrorBoundary } from './error-boundary'
import { ApiError } from './utils/api-client/user-platform-api-hooks'
import { WithHubspot } from './utils/higher-order-components/with-hubspot'
import { WithTheme } from './utils/higher-order-components/with-theme'
import { WithToasts } from './utils/higher-order-components/with-toasts'

const APP_VERSION = import.meta.env.VITE_APP_VERSION
console.log(`✨ User Platform v${APP_VERSION} ✨`)

export function Root() {
  const queryClient = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: true,
            staleTime: 1000 * 60, // 1 minute
            gcTime: 1000 * 60 * 60 * 24, // 24 hours
            throwOnError: (error: Error | ApiError) => {
              // Do not capture 499 errors to Sentry because they are expected
              if (error instanceof ApiError && error.status !== 499) Sentry.captureException(error)
              if (error instanceof ApiError && error.status === 499) return true
              if (error instanceof ApiError && error.status === 302) return true
              // 0 is the status code for hidden redirects (e.g. unauthenticated requests)
              if (error instanceof ApiError && error.status === 0) return true
              return false
            },
            retry: (failureCount, error: Error | ApiError) => {
              // Do not retry 403 Forbidden (unauthorized requests), 499, and 302 (unauthenticated requests)
              if (error instanceof ApiError && error.status === 403) return false
              if (error instanceof ApiError && error.status === 499) return false
              if (error instanceof ApiError && error.status === 302) return false
              // 0 is the status code for hidden redirects (e.g. unauthenticated requests)
              if (error instanceof ApiError && error.status === 0) return false
              return failureCount < 2
            },
            queryKeyHashFn: queryKey => {
              const keyWithVersion = [...queryKey, APP_VERSION]
              return JSON.stringify(keyWithVersion)
            },
          },
        },
        queryCache: new QueryCache(),
        mutationCache: new MutationCache(),
      })
  )[0]

  const [persister] = useState(() =>
    createSyncStoragePersister({
      storage: window.localStorage,
    })
  )

  const location = useLocation()

  return (
    <ErrorBoundary locationPathname={location.pathname}>
      <PersistQueryClientProvider client={queryClient} persistOptions={{ persister }}>
        <WithHubspot>
          <WithTheme>
            <WithToasts>
              <Outlet />
            </WithToasts>
          </WithTheme>
        </WithHubspot>
      </PersistQueryClientProvider>
    </ErrorBoundary>
  )
}
