import { GitMergeIcon, MarkGithubIcon } from '@primer/octicons-react'
import * as Dialog from '@radix-ui/react-dialog'
import { useState } from 'react'
import { match } from 'ts-pattern'
import { typography } from '../../main.css.ts'
import { mapToolToLogoAndName } from '../../routes/analysis-details-page'
import { usePostCreateWorkflow } from '../../utils/api-client/user-platform-api-hooks'
import { AnyRepositoryResponse, Tool, ToolLowercase } from '../../utils/api-client/user-platform-api-schemas'
import { useTheme } from '../../utils/higher-order-components/with-theme'
import { useAddToast } from '../../utils/higher-order-components/with-toasts'
import { DefaultButton } from '../default-button'
import { GhostButton } from '../ghost-button.tsx'
import { SelectBox } from '../select-box'
import AzureLightIcon from '../svg/azure-light.svg?react'
import GitlabIcon from '../svg/gitlab-icon.svg?react'
import * as styles from './upload-scan-form-drawer.css'
import * as localStyles from './workflow-drawer.css'

type WorkflowDrawerProps = {
  repositoryId: string
  repositoryDisplayName: string
  handleCloseDrawer: () => void
  repositoryType: AnyRepositoryResponse['type']
}

export function WorkflowDrawer({
  repositoryId,
  repositoryDisplayName,
  repositoryType,
  handleCloseDrawer,
}: WorkflowDrawerProps) {
  const { handleAddToastWithTimeout } = useAddToast()
  const [selectedType, setSelectedType] = useState<string>()
  const [selectedTool, setSelectedTool] = useState<string>()
  const [selectedCadence, setSelectedCadence] = useState<string>()
  const [workflowName, setWorkflowName] = useState<string>('')
  const { theme } = useTheme()

  const SCMIcon = match(repositoryType)
    .with('azure', () => <AzureLightIcon className={localStyles.scmIcon} />)
    .with('github', () => <MarkGithubIcon className={localStyles.scmIcon} />)
    .with('git', () => <GitMergeIcon aria-label="Git icon" className={localStyles.scmIcon} />)
    .with('gitlab', () => <GitlabIcon aria-label="Gitlab icon" className={localStyles.scmIcon} />)
    .otherwise(() => <GitMergeIcon aria-label="Git icon" className={localStyles.scmIcon} />)

  const validTools: Tool[] = [
    Tool.Enum.APPSCAN,
    Tool.Enum.POLARIS,
    Tool.Enum.CHECKMARX,
    Tool.Enum.CODEQL,
    Tool.Enum.SEMGREP,
    Tool.Enum.SNYK,
    Tool.Enum.SONAR,
  ]
  const tools: Tool[] = Tool.options.filter(tool => validTools.includes(tool as Tool))

  const createWorkflowMutation = usePostCreateWorkflow({
    onSuccess: () => {
      handleAddToastWithTimeout({ message: <>Workflow created successfully</>, variant: 'success' })
      handleCloseDrawer()
    },
    onError: error => {
      handleAddToastWithTimeout({
        message: <>Failed to create workflow: {error.message}</>,
        variant: 'error',
      })
    },
  })

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!selectedTool || !selectedCadence) return

    createWorkflowMutation.mutate({
      repositoryId,
      workflow: {
        name: workflowName,
        tool: selectedTool.toLowerCase() as ToolLowercase,
        event: {
          type: 'schedule',
          cadence: selectedCadence as 'daily' | 'weekly',
        },
        action: {
          type: 'create-patch',
        },
      },
    })
  }

  return (
    <Dialog.Root open={true} modal={false}>
      <Dialog.Portal>
        <Dialog.Content
          className={styles.content}
          onEscapeKeyDown={event => event.preventDefault()}
          onPointerDownOutside={event => event.preventDefault()}
          onInteractOutside={event => event.preventDefault()}
        >
          <Dialog.Title className={`${styles.title} ${localStyles.title}`}>
            <div
              className={`${typography.paragraphBold} ${localStyles.repoName}`}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              {SCMIcon}
              {repositoryDisplayName}
            </div>
            New workflow
          </Dialog.Title>
          <Dialog.Description className={`${styles.description} ${localStyles.separator}`}>
            Configure events and scope to result in designated actions
          </Dialog.Description>

          <form className={localStyles.workflowFormContainer} onSubmit={handleSubmit}>
            <div className={styles.flexContainer}>
              <SelectBox
                label="Type"
                name="workflowType"
                placeholder="Select workflow"
                value={selectedType}
                onValueChange={value => setSelectedType(value as string)}
                options={[
                  { value: 'scheduled', label: 'Scheduled fix' },
                  { value: 'scan', label: 'New scan (coming soon)', disabled: true, hideDisabledCheckIcon: true },
                ]}
              />
            </div>

            {selectedType === 'scheduled' && (
              <>
                <div className={localStyles.findingsContainer}>
                  <div className={localStyles.sectionHeading}>Which findings would you like to address?</div>
                  <SelectBox
                    label="Tool"
                    name="tool"
                    value={selectedTool}
                    onValueChange={value => setSelectedTool(value)}
                    options={tools
                      .filter(tool => tool != 'PIXEE')
                      .map(tool => {
                        const { name, logoHref } = mapToolToLogoAndName(tool, theme)
                        return {
                          value: tool,
                          label: (
                            <span className={styles.selectItemText}>
                              <img
                                src={logoHref}
                                alt={`${name} logo`}
                                width="16"
                                height="16"
                                style={{ marginRight: '8px' }}
                              />
                              {name}
                            </span>
                          ),
                        }
                      })}
                    placeholder="Select tool"
                  />
                </div>

                <div className={localStyles.findingsContainer}>
                  <div className={localStyles.sectionHeading}>Define a schedule</div>
                  <SelectBox
                    label="Cadence"
                    name="cadence"
                    value={selectedCadence}
                    onValueChange={value => setSelectedCadence(value)}
                    options={[
                      { value: 'daily', label: 'Daily' },
                      { value: 'weekly', label: 'Weekly' },
                    ]}
                    placeholder="Select cadence"
                  />
                </div>

                <div className={styles.flexContainer}>
                  <label htmlFor="workflowName" className={styles.inputLabel}>
                    Name (optional)
                  </label>
                  <input
                    type="text"
                    id="workflowName"
                    name="workflowName"
                    className={styles.inputField}
                    value={workflowName}
                    onChange={e => setWorkflowName(e.target.value)}
                    placeholder="Override the default workflow name with your own"
                  />
                </div>
              </>
            )}

            <div className={styles.uploadButtonContainer}>
              <GhostButton type="secondary" onClick={handleCloseDrawer}>
                Cancel
              </GhostButton>
              <DefaultButton
                buttonType="primary"
                state={selectedTool && selectedCadence ? 'default' : 'disabled'}
                buttonRole="submit"
              >
                Create workflow
              </DefaultButton>
            </div>
          </form>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
