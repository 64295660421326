import ReactMarkdown from 'react-markdown'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { materialDark, materialLight } from 'react-syntax-highlighter/dist/esm/styles/prism'
import remarkGfm from 'remark-gfm'
import { colors } from '../main.css.ts'
import { Theme } from '../utils/higher-order-components/with-theme'
import * as styles from './markdown.css'

export const Markdown = ({
  theme,
  language,
  markdown,
}: {
  theme: Theme
  language?: string
  markdown: string | undefined | null
}) => {
  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm]}
      className={styles.description}
      components={{
        a: ({ children, ...props }) => (
          <a className={styles.markdownLink} target="_blank" rel="noreferrer" {...props}>
            {children}
          </a>
        ),
        code: ({ node, ...props }) => <InlineCode language={language ?? 'java'} theme={theme} {...props} />,
        pre: ({ node, ...props }) => (
          <CodeBlock language={language ?? 'java'} theme={theme} showLineNumbers={false} {...props} />
        ),
      }}
    >
      {markdown}
    </ReactMarkdown>
  )
}

const InlineCode = ({
  language,
  theme,
  children,
  ...props
}: React.HTMLProps<HTMLElement> & { language: string; theme: Theme }) => {
  return (
    <SyntaxHighlighter
      language={language}
      style={theme === 'dark' ? materialDark : materialLight}
      PreTag="code"
      CodeTag="code"
      customStyle={{
        margin: '0',
        padding: '4px 8px',
        borderRadius: '8px',
        backgroundColor: theme === 'dark' ? colors.neutral.neutral80 : colors.neutral.neutral20,
        width: '100%',
      }}
      codeTagProps={{
        style: {
          fontFamily: "'Inconsolata', monospace",
          fontSize: '1rem',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '24px',
          color: theme === 'dark' ? colors.neutral.neutral10 : colors.neutral.neutral90,
        },
      }}
      {...props}
    >
      {children}
    </SyntaxHighlighter>
  )
}

const CodeBlock = ({
  language,
  theme,
  showLineNumbers,
  children,
  ...props
}: React.HTMLProps<HTMLElement> & { language: string; theme: Theme; showLineNumbers: boolean }) => {
  // @ts-expect-error
  const code = children?.props?.children
  const codeLines = code?.split('\n')

  const addedLines = codeLines?.reduce(
    (acc: number[], line: string, index: number) => (line.startsWith('+') ? [index + 1, ...acc] : acc),
    []
  )
  const removedLines = codeLines?.reduce(
    (acc: number[], line: string, index: number) => (line.startsWith('-') ? [index + 1, ...acc] : acc),
    []
  )

  return (
    <SyntaxHighlighter
      language={language}
      style={theme === 'dark' ? materialDark : materialLight}
      customStyle={{
        borderRadius: '8px',
        backgroundColor: theme === 'dark' ? colors.neutral.neutral80 : colors.neutral.neutral20,
        color: theme === 'dark' ? colors.neutral.neutral10 : colors.neutral.neutral90,
      }}
      codeTagProps={{
        style: {
          fontFamily: "'Inconsolata', monospace",
          fontSize: '1rem',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '24px',
        },
      }}
      wrapLines={true}
      showLineNumbers={showLineNumbers}
      lineProps={(lineNumber: number) => {
        if (removedLines?.includes(lineNumber))
          return {
            style: {
              backgroundColor: theme === 'dark' ? '#4B1818' : '#FFDCE0',
            },
          } as React.HTMLAttributes<HTMLElement>
        if (addedLines?.includes(lineNumber))
          return {
            style: {
              backgroundColor: theme === 'dark' ? '#2D4F2D' : ' #E0F2E9',
            },
          } as React.HTMLAttributes<HTMLElement>
        return {} as React.HTMLAttributes<HTMLElement>
      }}
      {...props}
    >
      {code}
    </SyntaxHighlighter>
  )
}
