import * as Accordion from '@radix-ui/react-accordion'
import * as Dialog from '@radix-ui/react-dialog'
import { ChevronDownIcon, ChevronUpIcon, Cross2Icon } from '@radix-ui/react-icons'
import * as Tabs from '@radix-ui/react-tabs'
import { match } from 'ts-pattern'
import { Changeset, Fix } from '../../utils/api-client/user-platform-api-schemas'
import { Theme } from '../../utils/higher-order-components/with-theme'
import { CopyButton } from '../copy-button'
import { DefaultButton } from '../default-button'
import { FixTag } from '../fix-tag'
import { Markdown } from '../markdown'
import { Tooltip } from '../tooltip'
import * as styles from './preview-fix-drawer.css'

export const PreviewFixDrawerPure: React.FC<{
  fix?: Fix
  changesets?: Changeset[]
  isSendPatchLoading?: boolean
  handleSendPatch?: () => void
  onClose?: () => void
  theme?: Theme
}> = ({ fix, changesets, isSendPatchLoading = false, onClose = () => {}, theme = 'dark', handleSendPatch }) => {
  return (
    <Dialog.Root open={true} modal={false}>
      <Dialog.Portal>
        <Dialog.Content className={styles.content} aria-describedby={undefined}>
          <div className={styles.header}>
            <Dialog.Title className={styles.title}>{fix?.summary}</Dialog.Title>
            <div className={styles.fixIdLineContainer}>
              <p className={styles.fixIdGroup}>
                <span className={styles.fixIdLabel}>FIX</span>
                <span>{fix?.id}</span>
                <CopyButton text={fix?.id ?? ''} label="FIX ID" />
                <DefaultButton
                  state={isSendPatchLoading ? 'loading' : 'default'}
                  ariaLabel={'Send Patch'}
                  loadingLabel={'Sending Patch...'}
                  onClick={handleSendPatch}
                >
                  Send Patch
                </DefaultButton>
              </p>
              {fix?.strategy && (
                <div className={styles.fixTagWithTooltip}>
                  <FixTag strategy={fix.strategy} />
                  <Tooltip>
                    {match(fix.strategy)
                      .with('deterministic', () => (
                        <>
                          <b>Deterministic fixes</b> are developed by Pixee using traditional algorithmic means.
                          Generative AI is NOT used to write source code.
                        </>
                      ))
                      .with('ai', () => (
                        <>
                          <b>AI fixes</b> are architected by Pixee to leverage specialized AI directed at the explicit
                          finding type and instance.
                        </>
                      ))
                      .with('hybrid', () => (
                        <>
                          <b>Hybrid fixes</b> are architected by Pixee to incorporate both deterministic and directed AI
                          techniques.
                        </>
                      ))
                      .with('provisional_ai', () => (
                        <>
                          <b>Provisional fixes</b> are those which rely on more abstract and generic techniques to
                          create the fix. As a result, these fixes may be more unpredictable and warrant additional
                          review.
                        </>
                      ))
                      .exhaustive()}
                  </Tooltip>
                </div>
              )}
            </div>
          </div>
          <Dialog.Close asChild className={styles.closeButton}>
            <button aria-label="Close" onClick={onClose}>
              <Cross2Icon className={styles.closeButtonIcon} />
            </button>
          </Dialog.Close>
          <Tabs.Root defaultValue="overview" className={styles.tabContainer}>
            <Tabs.List className={styles.tabList}>
              <Tabs.Trigger className={styles.tab} value="overview">
                OVERVIEW
              </Tabs.Trigger>
              <Tabs.Trigger className={styles.tab} value="files-changed">
                FILES CHANGED <span className={styles.changesetCount}>{changesets?.length}</span>
              </Tabs.Trigger>
            </Tabs.List>

            <Tabs.Content className={styles.tabContent} value="overview">
              {fix?.description ? (
                <Markdown
                  language={getCodemodLanguageByCodemodId(fix?.codemod) ?? 'text'}
                  markdown={fix.description}
                  theme={theme}
                />
              ) : (
                <p className={styles.description}>No description available for this fix.</p>
              )}
            </Tabs.Content>
            <Tabs.Content className={styles.tabContent} value="files-changed">
              {changesets && <ChangesetBlocksPure changesets={changesets} theme={theme} />}
            </Tabs.Content>
          </Tabs.Root>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

export const getCodemodLanguageByCodemodId = (codemodId: string | undefined): string | undefined => {
  if (!codemodId) return undefined
  const [_, language] = codemodId.split(/:([^/]+)\//)
  return language
}

const ChangesetBlocksPure = ({ changesets, theme }: { changesets: Changeset[]; theme: Theme }) => {
  return (
    <Accordion.Root
      type="multiple"
      defaultValue={changesets.map(changeset => changeset.path + changeset.preview.length)}
      className={styles.accordion}
    >
      {changesets.map(changeset => (
        <Accordion.Item
          key={changeset.path + changeset.preview.length}
          value={changeset.path + changeset.preview.length}
          className={styles.accordionItem}
        >
          <Accordion.Header className={styles.accordionHeader}>
            <Accordion.Trigger className={styles.accordionTrigger}>
              <ChevronDownIcon className={styles.chevronDown} />
              <ChevronUpIcon className={styles.chevronUp} />
              {changeset.path}
            </Accordion.Trigger>
          </Accordion.Header>
          <Accordion.Content>
            <Markdown theme={theme} markdown={'```\n' + changeset.preview + '\n```'} />
          </Accordion.Content>
        </Accordion.Item>
      ))}
    </Accordion.Root>
  )
}
