import { CheckIcon, TriangleDownIcon, TriangleUpIcon } from '@radix-ui/react-icons'
import * as Select from '@radix-ui/react-select'
import { useEffect, useState } from 'react'
import * as styles from './select-box.css'

interface SelectBoxProps {
  label: string
  value?: string
  onValueChange?: (value: string) => void
  placeholder?: string
  disabled?: boolean
  name?: string
  options: Array<{
    value: string
    label: string | JSX.Element
    disabled?: boolean
    hideDisabledCheckIcon?: boolean
  }>
}

export function SelectBox({
  label,
  value,
  onValueChange,
  placeholder = 'Select an option',
  disabled = false,
  name,
  options,
}: SelectBoxProps) {
  const [internalValue, setInternalValue] = useState<string>(value ?? '')

  useEffect(() => {
    if (value !== undefined) {
      setInternalValue(value)
    }
  }, [value])

  const handleValueChange = (newValue: string) => {
    onValueChange?.(newValue)
  }

  return (
    <label className={styles.inputLabel}>
      {label}
      <Select.Root value={internalValue} onValueChange={handleValueChange} disabled={disabled}>
        <Select.Trigger className={styles.selectTrigger}>
          <Select.Value placeholder={placeholder} />
          <Select.Icon>
            <TriangleDownIcon className={styles.chevronDown} />
            <TriangleUpIcon className={styles.chevronUp} />
          </Select.Icon>
        </Select.Trigger>
        <Select.Content
          className={styles.selectContent}
          position="popper"
          side="bottom"
          align="start"
          sideOffset={-21}
          avoidCollisions={false}
        >
          <Select.Viewport className={styles.selectViewport}>
            {options.map(option => (
              <Select.Item
                key={option.value}
                value={option.value}
                className={styles.selectItem}
                disabled={option.disabled}
              >
                <Select.ItemText asChild>
                  <span>{option.label}</span>
                </Select.ItemText>
                {!option.hideDisabledCheckIcon &&
                  (option.disabled ? (
                    <CheckIcon className={styles.disabledCheckboxIcon} />
                  ) : (
                    <Select.ItemIndicator>
                      <CheckIcon className={styles.checkboxIcon} />
                    </Select.ItemIndicator>
                  ))}
              </Select.Item>
            ))}
          </Select.Viewport>
        </Select.Content>
      </Select.Root>
      {name && <input type="hidden" name={name} value={internalValue} />}
    </label>
  )
}
