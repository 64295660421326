import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import classNames from 'classnames'
import { useMemo } from 'react'
import { match } from 'ts-pattern'
import { FindingDetailsDrawerPure } from '../components/drawer/finding-details-drawer.tsx'
import { PreviewFixDrawerPure } from '../components/drawer/preview-fix-drawer.tsx'
import { SeverityBadge } from '../components/severity-badge'
import { SuggestedSeverity } from '../components/suggested-severity.tsx'
import { Pagination, Table } from '../components/table'
import { typography } from '../main.css.ts'
import {
  Changeset,
  Finding,
  Fix,
  PaginatedFindings,
  TriagedFindingResponse,
} from '../utils/api-client/user-platform-api-schemas'
import { Theme } from '../utils/higher-order-components/with-theme'
import { useEnvironmentData } from '../utils/hooks/use-environment-data'
import { DefaultButton } from './default-button.tsx'
import { DropdownFilter } from './dropdown-filter.tsx'
import * as styles from './findings-table.css'
import { Tooltip } from './tooltip'

type FindingsTablePureProps = {
  paginatedFindings?: PaginatedFindings
  pagination?: Pagination
  handlePaginationChange: (pagination: Pagination) => void
  previewFixDrawer?: (props: { findingId: string }) => ReturnType<typeof PreviewFixDrawerPure>
  selectedFinding?: Finding
  selectedFindingMarkdown?: string
  setSelectedFindingId?: React.Dispatch<React.SetStateAction<string | undefined>>
  selectedFindingIdForFix?: string | undefined
  handleSetSelectedFindingIdForFix?: (findingId: string | undefined) => void
  selectedFindingFixes?: Fix[]
  selectedFindingChangesets?: Changeset[]
  handleSendPatch?: () => void
  isSendPatchLoading?: boolean
  theme: Theme
  handleToggleHasFix?: () => void
  handleToggleHasNoFix?: () => void
  hasFix?: boolean
  hasNoFix?: boolean
}

const SelectableFindingCell: React.FC<{
  children: React.ReactNode
  handleClick: () => void
  isSelectable?: boolean
}> = ({ children, handleClick, isSelectable = true }) => (
  <div
    onClick={isSelectable ? handleClick : undefined}
    data-testid="selectable-finding-cell"
    style={isSelectable ? { cursor: 'pointer' } : undefined}
  >
    {children}
  </div>
)

export const FindingsTablePure: React.FC<FindingsTablePureProps> = ({
  paginatedFindings,
  pagination,
  handlePaginationChange,
  selectedFinding,
  selectedFindingMarkdown,
  setSelectedFindingId = () => {},
  selectedFindingIdForFix,
  handleSetSelectedFindingIdForFix = () => {},
  selectedFindingFixes,
  selectedFindingChangesets,
  handleSendPatch,
  isSendPatchLoading,
  theme,
  handleToggleHasFix = () => {},
  handleToggleHasNoFix = () => {},
  hasFix = false,
  hasNoFix = false,
}) => {
  const environmentData = useEnvironmentData()
  const columns = useMemo(
    () =>
      [
        {
          accessorKey: 'severity',
          meta: { width: '5%' },
          header: () => (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <span
                className={styles.header}
                style={{ margin: 0, marginRight: '8px', padding: 0, width: 'fit-content' }}
              >
                SEVERITY
              </span>
            </div>
          ),
          cell: ({ row }) => {
            const isTriaged = row.original._embedded?.triage?.result === 'triaged'
            return (
              <SelectableFindingCell handleClick={() => setSelectedFindingId(row.original.id)} isSelectable={isTriaged}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                  <SeverityBadge severity={row.original.severity} />
                </div>
              </SelectableFindingCell>
            )
          },
        },
        {
          accessorKey: 'title',
          meta: { width: '26%' },
          header: () => (
            <span className={styles.header} style={{ justifyContent: 'flex-start' }}>
              FINDING
            </span>
          ),
          cell: ({ row }) => {
            const isTriaged = row.original._embedded?.triage?.result === 'triaged'
            return (
              <SelectableFindingCell handleClick={() => setSelectedFindingId(row.original.id)} isSelectable={isTriaged}>
                <p className={styles.findingTitle}>
                  {row.original.title && row.original.title.length > 52
                    ? row.original.title.slice(0, 49) + '...'
                    : (row.original.title ?? 'No title')}
                </p>
                <p className={styles.findingRule}>{row.original.rule}</p>
              </SelectableFindingCell>
            )
          },
        },
        {
          accessorKey: 'suggestedStatus',
          meta: { width: '13%' },
          header: () => (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <img
                src={environmentData.logoIconHref}
                alt={environmentData.logoIconAlt}
                height={16}
                style={{ marginRight: '8px' }}
              />
              <span
                className={styles.header}
                style={{
                  margin: 0,
                  marginRight: '8px',
                  padding: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: 'fit-content',
                }}
              >
                CLASSIFICATION
              </span>
              <Tooltip side="bottom" width={422}>
                <div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: '8px',
                      justifyContent: 'space-between',
                    }}
                  >
                    <img src={environmentData.logoIconHref} alt={environmentData.logoIconAlt} height={24} />
                    <span className={typography.leadBold}>Triage</span>
                  </div>
                  <div>
                    <p className={typography.smallBold} style={{ margin: 0 }}>
                      True positive
                    </p>
                    <p className={typography.smallItalic} style={{ margin: 0 }}>
                      Pixee can validate the finding as a legitimate issue
                    </p>
                  </div>
                  <div style={{ marginTop: '8px' }}>
                    <p className={typography.smallBold} style={{ margin: 0 }}>
                      False positive
                    </p>
                    <p className={typography.smallItalic} style={{ margin: 0 }}>
                      Pixee can confidently dismiss this finding as a false positive, though we may still offer a fix to
                      satisfy the tool
                    </p>
                  </div>
                  <div style={{ marginTop: '8px' }}>
                    <p className={typography.smallBold} style={{ margin: 0 }}>
                      Suspicious
                    </p>
                    <p className={typography.smallItalic} style={{ margin: 0 }}>
                      Pixee suspects a true positive, given the absence of typical false positive conditions or security
                      controls
                    </p>
                  </div>
                  <div style={{ marginTop: '8px' }}>
                    <p className={typography.smallBold} style={{ margin: 0 }}>
                      Won't fix
                    </p>
                    <p className={typography.smallItalic} style={{ margin: 0 }}>
                      The finding is considered negligible, in that the vast majority of teams would choose to accept
                      any potential risk
                    </p>
                  </div>
                  <div style={{ marginTop: '8px' }}>
                    <p className={typography.smallBold} style={{ margin: 0 }}>
                      No analysis
                    </p>
                    <p className={typography.smallItalic} style={{ margin: 0 }}>
                      Pixee was not able to triage the finding
                    </p>
                  </div>
                  <div style={{ marginTop: '8px' }}>
                    <p className={typography.smallBold} style={{ margin: 0 }}>
                      Null
                    </p>
                    <p className={typography.smallItalic} style={{ margin: 0 }}>
                      Not classifiable by Pixee, though there may be analysis and/or a fix
                    </p>
                  </div>
                </div>
              </Tooltip>
            </div>
          ),
          cell: ({ row }) => {
            if (row.original._embedded?.triage && row.original._embedded.triage.suggested_status) {
              const isTriaged = row.original._embedded?.triage?.result === 'triaged'
              return (
                <SelectableFindingCell
                  handleClick={() => setSelectedFindingId(row.original.id)}
                  isSelectable={isTriaged}
                >
                  <span className={styles.suggestedStatusCell[row.original._embedded.triage.suggested_status]}>
                    {getSuggestedStatusLabel(row.original._embedded.triage.suggested_status)}
                  </span>
                </SelectableFindingCell>
              )
            }

            const isTriaged = row.original._embedded?.triage?.result === 'triaged'
            return (
              <SelectableFindingCell handleClick={() => setSelectedFindingId(row.original.id)} isSelectable={isTriaged}>
                <span className={styles.suggestedStatusCell.null}>-</span>
              </SelectableFindingCell>
            )
          },
        },
        {
          accessorKey: 'severityUpdate',
          meta: { width: '13%' },
          header: () => (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <img
                src={environmentData.logoIconHref}
                alt={environmentData.logoIconAlt}
                height={16}
                style={{ marginRight: '8px' }}
              />
              <span
                className={styles.header}
                style={{ margin: 0, marginRight: '8px', padding: 0, width: 'fit-content' }}
              >
                SEVERITY
              </span>
              <Tooltip side="bottom">
                <div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: '8px',
                      justifyContent: 'space-between',
                    }}
                  >
                    <img src={environmentData.logoIconHref} alt={environmentData.logoIconAlt} height={24} />
                    <span className={typography.leadBold}>Triage</span>
                  </div>
                  <p className={typography.small} style={{ margin: 0 }}>
                    Based on triage analysis, Pixee may be able to suggest the severity is actually higher or lower than
                    the tool initially reported.
                  </p>
                </div>
              </Tooltip>
            </div>
          ),
          cell: ({ row }) => {
            const isTriaged = row.original._embedded?.triage?.result === 'triaged'
            return (
              <SelectableFindingCell handleClick={() => setSelectedFindingId(row.original.id)} isSelectable={isTriaged}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                  <SuggestedSeverity
                    severity={row.original.severity}
                    suggestedSeverity={row.original._embedded?.triage?.suggested_severity ?? null}
                  />
                </div>
              </SelectableFindingCell>
            )
          },
        },
        {
          accessorKey: 'summary',
          meta: { width: '16%' },
          header: () => (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
              <img
                src={environmentData.logoIconHref}
                alt={environmentData.logoIconAlt}
                height={16}
                style={{ marginRight: '8px' }}
              />
              <span className={styles.header} style={{ margin: 0, padding: 0, width: 'fit-content' }}>
                ANALYSIS
              </span>
            </div>
          ),
          cell: ({ row }) => {
            const isTriaged = row.original._embedded?.triage?.result === 'triaged'
            return row.original._embedded?.triage?.summary ? (
              <SelectableFindingCell handleClick={() => setSelectedFindingId(row.original.id)} isSelectable={isTriaged}>
                <span className={styles.analysisCell}>
                  {row.original._embedded.triage.summary.length > 30
                    ? row.original._embedded.triage.summary.slice(0, 30) + '...'
                    : row.original._embedded.triage.summary}
                </span>
              </SelectableFindingCell>
            ) : (
              <SelectableFindingCell handleClick={() => setSelectedFindingId(row.original.id)} isSelectable={isTriaged}>
                <span className={styles.placeholderCell}>-</span>
              </SelectableFindingCell>
            )
          },
        },
        {
          accessorKey: 'fix',
          meta: { width: '14%' },
          header: () => (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <span className={styles.header} style={{ margin: 0, padding: 0, width: 'fit-content' }}>
                FIX
              </span>
            </div>
          ),
          cell: ({ row }) =>
            row.original._embedded?.fixes.total && row.original._embedded.fixes.total > 0 ? (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <DefaultButton
                  buttonType={'secondary'}
                  size="small"
                  onClick={() => handleSetSelectedFindingIdForFix(row.original.id)}
                >
                  Preview
                </DefaultButton>
              </div>
            ) : (
              <div className={styles.placeholderCell} style={{ justifyContent: 'center' }}>
                <span style={{ display: 'flex', justifyContent: 'center' }}>-</span>
              </div>
            ),
        },
      ] as ColumnDef<Finding>[],
    [setSelectedFindingId]
  )

  const rowSelection = useMemo(() => {
    if (!selectedFinding) {
      return {}
    }

    return {
      [selectedFinding.id]: true,
    }
  }, [selectedFinding])

  const table = useReactTable<Finding>({
    data: paginatedFindings?._embedded?.items ?? [],
    getRowId: finding => finding.id,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      rowSelection,
    },
  })

  return (
    <>
      {selectedFinding && (
        <FindingDetailsDrawerPure
          finding={selectedFinding}
          markdown={selectedFindingMarkdown}
          handleClose={() => setSelectedFindingId(undefined)}
          theme={theme}
        />
      )}
      {selectedFindingIdForFix && (
        <PreviewFixDrawerPure
          fix={selectedFindingFixes?.[0]}
          changesets={selectedFindingChangesets}
          onClose={() => handleSetSelectedFindingIdForFix(undefined)}
          theme={theme}
          isSendPatchLoading={isSendPatchLoading}
          handleSendPatch={handleSendPatch}
        />
      )}
      <div className={styles.tableControls}>
        <DropdownFilter
          filterGroups={[
            {
              id: 'availableFix',
              text: 'Available Fix',
              filters: [
                { id: 'hasFix', text: 'Yes', value: hasFix, handleToggle: handleToggleHasFix },
                { id: 'hasNoFix', text: 'No', value: hasNoFix, handleToggle: handleToggleHasNoFix },
              ],
            },
          ]}
        />
      </div>
      <Table
        table={table}
        isLoading={paginatedFindings === undefined}
        pagination={pagination}
        handlePaginationChange={handlePaginationChange}
        tableLabel="Findings"
        rowCount={paginatedFindings?.total}
        getRowClassName={row => {
          const isTriaged = row.original._embedded?.triage?.result === 'triaged'
          const className = classNames({
            [styles.selectableRow]: isTriaged,
          })
          return className
        }}
      />
    </>
  )
}

export const getSuggestedStatusLabel = (status: TriagedFindingResponse['suggested_status']) => {
  return match(status)
    .with('wont_fix', () => "Won't fix")
    .with('false_positive', () => 'False positive')
    .with('true_positive', () => 'True positive')
    .with('suspicious', () => 'Suspicious')
    .with(null, () => '-')
    .exhaustive()
}
