import { z } from 'zod'

z.setErrorMap((issue, ctx) => {
  console.error(
    // @ts-expect-error
    `Zod Error "${ctx.defaultError}": ${issue.code} at path '${issue.path.join('.')}'. Expected ${issue.expected}, received ${issue.received}. Data: ${JSON.stringify(ctx.data) || ctx.data}`
  )
  return { message: ctx.defaultError }
})

export const User = z.object({
  login: z.string(),
  name: z.string().nullable(),
  avatar_url: z.string(),
  email: z.string(),
})
export type User = z.infer<typeof User>
export const Account = z.object({
  id: z.number(),
  login: z.string(),
  avatar_url: z.string(),
  type: z.enum(['User', 'Organization']),
})
export type Account = z.infer<typeof Account>
export const RepositoryType = z.enum(['azure', 'git', 'github', 'gitlab'])
export type RepositoryType = z.infer<typeof RepositoryType>

const Link = z.object({
  href: z.string(),
  title: z.string().optional(),
})

const RepositoryLinks = z.object({
  self: Link,
  'latest-analysis': Link,
})

export const RepositoryResponse = z.object({
  type: RepositoryType.or(z.string()),
  _links: RepositoryLinks,
  id: z.string(),
  name: z.string(),
  default_branch: z.string(),
})
export type RepositoryResponse = z.infer<typeof RepositoryResponse>

export const GitHubRepositoryResponse = z.object({
  type: RepositoryType.or(z.string()),
  _links: RepositoryLinks,
  id: z.string(),
  name: z.string(),
  default_branch: z.string(),
  html_url: z.string(),
})
export const AnyRepositoryResponse = RepositoryResponse.or(GitHubRepositoryResponse)
export type AnyRepositoryResponse = z.infer<typeof AnyRepositoryResponse>
export type GitHubRepositoryResponse = z.infer<typeof GitHubRepositoryResponse>

const PageResponse = z.object({
  number: z.number().int(),
  size: z.number().int(),
})

export const PaginatedRepositories = z.object({
  _links: z.object({
    self: Link,
  }),
  total: z.number(),
  page: PageResponse,
  _embedded: z.object({
    items: z.array(AnyRepositoryResponse),
  }),
})
export type PaginatedRepositories = z.infer<typeof PaginatedRepositories>
export const Severity = z.object({
  label: z.string().nullable(),
  rank: z.number().min(0).max(100),
})
export type Severity = z.infer<typeof Severity>
export const FindingStatusResponse = z.enum(['wont_fix', 'false_positive', 'true_positive', 'suspicious'])
export const TriagedFindingResponse = z.object({
  result: z.enum(['triaged', 'error', 'rule_not_supported']),
  suggested_status: FindingStatusResponse.nullable(),
  suggested_severity: Severity.nullable(),
  summary: z.string().nullable(),
})
export type TriagedFindingResponse = z.infer<typeof TriagedFindingResponse>
export const Tool = z.enum([
  'APPSCAN',
  'POLARIS',
  'CHECKMARX',
  'CODEQL',
  'CONTRAST',
  'DEFECT_DOJO',
  // NOTE 28/01/2025: Pixee enum seems to be never used in practice, we don't implement it in scan analysis page
  'PIXEE',
  'SEMGREP',
  'SNYK',
  'SONAR',
])
export type Tool = z.infer<typeof Tool>
const ToolLowercase = z.enum([
  'appscan',
  'checkmarx',
  'codeql',
  'contrast',
  'defectdojo',
  'semgrep',
  'snyk',
  'sonar',
  'polaris',
])
export type ToolLowercase = z.infer<typeof ToolLowercase>
export const CreateScanResponse = z.object({
  _links: z.object({
    self: Link,
    repository: Link.extend({
      title: z.string(),
    }),
    scale: Link,
  }),
  uuid: z.string(),
  detector: Tool,
  sha: z.string().nullable(),
  scanned_at: z.string().nullable(),
  imported_at: z.string(),
  html_url: z.string().nullable(),
  branch: z.string().nullable(),
})
export type CreateScanResponse = z.infer<typeof CreateScanResponse>
const CreateScanRequestMetadata = z.object({
  tool: ToolLowercase,
  sha: z.string().optional(),
  branch: z.string().optional(),
})
type CreateScanRequestMetadata = z.infer<typeof CreateScanRequestMetadata>
export const CreateScanRequestFormData = z.object({
  metadata: CreateScanRequestMetadata,
  files: z.record(z.string(), z.instanceof(File)),
})
export type CreateScanRequestFormData = z.infer<typeof CreateScanRequestFormData>
export const Credentials = z.object({
  username: z.string(),
  password: z.string(),
})
export type Credentials = z.infer<typeof Credentials>
const FindingTriage = z.object({
  result: z.enum(['triaged', 'error', 'rule_not_supported']),
  suggested_status: FindingStatusResponse.nullable(),
  suggested_severity: Severity.nullable(),
  summary: z.string().nullable(),
})
type FindingTriage = z.infer<typeof FindingTriage>
const FindingFixes = z.object({
  total: z.number().int(),
})
type FindingFixes = z.infer<typeof FindingFixes>
const FindingEmbedded = z.object({
  triage: FindingTriage.nullable(),
  fixes: FindingFixes,
})
type FindingEmbedded = z.infer<typeof FindingEmbedded>
export const Finding = z.object({
  id: z.string(),
  title: z.string().nullable(),
  rule: z.string(),
  severity: Severity.nullable(),
  _embedded: FindingEmbedded.optional(),
})
export type Finding = z.infer<typeof Finding>
export const PaginatedFindings = z.object({
  total: z.number().int(),
  false_positives: z.number(),
  true_positives: z.number(),
  suspicious: z.number(),
  wont_fix: z.number(),
  fixes: z.number(),
  page: PageResponse,
  _embedded: z
    .object({
      items: z.array(Finding).optional(),
    })
    .optional(),
})
export type PaginatedFindings = z.infer<typeof PaginatedFindings>
export const Fix = z.object({
  id: z.string(),
  result: z.enum(['completed_results', 'completed_no_results', 'failed']),
  codemod: z.string(),
  summary: z.string().nullable(),
  description: z.string().nullable(),
  strategy: z.enum(['provisional_ai', 'ai', 'deterministic', 'hybrid']),
})
export type Fix = z.infer<typeof Fix>
export const PaginatedFixes = z.object({
  total: z.number().int(),
  page: PageResponse,
  _embedded: z
    .object({
      items: z.array(Fix).optional(),
    })
    .optional(),
})
export type PaginatedFixes = z.infer<typeof PaginatedFixes>
export const Changeset = z.object({
  path: z.string(),
  preview: z.string(),
})
export type Changeset = z.infer<typeof Changeset>
export const PaginatedChangesets = z.object({
  total: z.number().int(),
  page: PageResponse,
  _embedded: z
    .object({
      items: z.array(Changeset).optional(),
    })
    .optional(),
})
export type PaginatedChangesets = z.infer<typeof PaginatedChangesets>
export const AnalysisStateResponse = z.enum([
  'queued',
  'in_progress',
  'skipped',
  'completed_results',
  'completed_no_results',
  'failed',
])
export type AnalysisStateResponse = z.infer<typeof AnalysisStateResponse>
const AnalysisStateLogResponse = z.object({
  state: AnalysisStateResponse,
  timestamp: z.string(),
})
type AnalysisStateLogResponse = z.infer<typeof AnalysisStateLogResponse>
export const ScanAnalysis = z.object({
  id: z.string(),
  current_state: AnalysisStateLogResponse,
  log: z.array(AnalysisStateLogResponse),
  sha: z.string().optional().nullable(),
  branch: z.string().optional().nullable().describe('[@deprecated] Use _embedded.scan.branch instead'),
  _links: z.object({
    self: Link,
    scan: Link,
    findings: Link,
    patches: Link,
  }),
  _embedded: z.object({
    scan: z.object({
      _links: z.object({
        self: Link,
        repository: Link,
        scale: Link,
      }),
      uuid: z.string(),
      detector: Tool,
      sha: z.string(),
      scanned_at: z.string().nullable(),
      imported_at: z.string(),
      html_url: z.string().nullable(),
      branch: z.string().nullable(),
    }),
  }),
})
export type ScanAnalysis = z.infer<typeof ScanAnalysis>
export const PaginatedAnalysisResponse = z.object({
  _links: z.object({
    self: Link,
  }),
  total: z.number(),
  page: PageResponse,
  _embedded: z.object({
    items: z.array(ScanAnalysis),
  }),
})
export type PaginatedAnalysisResponse = z.infer<typeof PaginatedAnalysisResponse>
export const PatchStateResponse = z.enum(['in_progress', 'completed_success', 'completed_failed'])
export type PatchStateResponse = z.infer<typeof PatchStateResponse>
export const PatchResponse = z.object({
  id: z.string(),
  analysis_id: z.string(),
  created_at: z.string(),
  state: PatchStateResponse,
})
export type PatchResponse = z.infer<typeof PatchResponse>
export const CreatePatchRequest = z.object({
  findings: z.array(z.string()).min(1).max(1),
})
export type CreatePatchRequest = z.infer<typeof CreatePatchRequest>
const HALPaginatedGeneric = <T extends z.ZodType>(itemSchema: T) =>
  z.object({
    _links: z.object({
      self: Link,
    }),
    total: z.number().int(),
    page: PageResponse,
    _embedded: z
      .object({
        items: z.array(itemSchema).optional(),
      })
      .optional(),
  })
type HALPaginatedGeneric<T extends z.ZodType> = z.infer<ReturnType<typeof HALPaginatedGeneric<T>>>

export const AzureOrganization = z.object({
  name: z.string(),
})
export type AzureOrganization = z.infer<typeof AzureOrganization>

export const AzureProject = z.object({
  id: z.string(),
  name: z.string(),
})
export type AzureProject = z.infer<typeof AzureProject>

export const AzureRepository = z.object({
  id: z.string(),
  name: z.string(),
  project: AzureProject,
  pixee_id: z.string().nullable(),
})
export type AzureRepository = z.infer<typeof AzureRepository>

export const HALPaginatedAzureOrganizations = HALPaginatedGeneric(AzureOrganization)
export type HALPaginatedAzureOrganizations = z.infer<typeof HALPaginatedAzureOrganizations>

export const HALPaginatedAzureProjects = HALPaginatedGeneric(AzureProject)
export type HALPaginatedAzureProjects = z.infer<typeof HALPaginatedAzureProjects>

export const HALPaginatedAzureRepositories = HALPaginatedGeneric(AzureRepository)
export type HALPaginatedAzureRepositories = z.infer<typeof HALPaginatedAzureRepositories>

export const CreateWorkflowRequest = z.object({
  name: z.string().optional(),
  tool: ToolLowercase,
  event: z.object({
    type: z.literal('schedule'),
    cadence: z.enum(['daily', 'weekly']),
    start: z.string().datetime().optional(),
  }),
  action: z.object({
    type: z.literal('create-patch'),
    include_severity_labels: z.array(z.string()).optional(),
  }),
})
export type CreateWorkflowRequest = z.infer<typeof CreateWorkflowRequest>

export const FindingTriageArticle = z.string()
export type FindingTriageArticle = z.infer<typeof FindingTriageArticle>

export const CreateWorkflowResponse = z.object({
  type: z.string(),
  _links: z.object({
    self: Link,
    repository: Link,
  }),
  id: z.string(),
  name: z.string().nullable(),
})
export type CreateWorkflowResponse = z.infer<typeof CreateWorkflowResponse>

export const CreateAnalysisResponse = z.object({
  _links: z.object({
    self: Link,
    findings: Link,
    patches: Link,
    scan: Link,
  }),
  id: z.string(),
  current_state: z.object({
    state: z.string(),
    timestamp: z.string(),
  }),
  log: z.array(
    z.object({
      state: z.string(),
      timestamp: z.string(),
    })
  ),
  sha: z.string().nullable(),
  branch: z.string().nullable(),
  _embedded: z.object({
    scan: CreateScanResponse,
  }),
})
export type CreateAnalysisResponse = z.infer<typeof CreateAnalysisResponse>

export const CreateRepositoryResponse = z.object({
  type: z.string(),
  _links: z.object({
    self: Link,
    'latest-analysis': Link,
  }),
  id: z.string(),
  name: z.string(),
  default_branch: z.string().nullable(),
})
export type CreateRepositoryResponse = z.infer<typeof CreateRepositoryResponse>

export const CreatePatchResponse = z.object({
  type: z.string(),
  _links: z.object({
    self: Link,
  }),
  id: z.string(),
  state: PatchStateResponse,
  branch_name: z.string().nullable(),
})
export type CreatePatchResponse = z.infer<typeof CreatePatchResponse>
